import { PopupModal } from "../../../../widgets";
import { Radio, RadioChangeEvent, Space } from "antd";
import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useNavigate } from "react-router";

export const AddDocumentModal = observer(() => {
  const {
    RootStore: {
      RegistryStore: {
        setFirstRegistryModalIsOpen,
        setPickedRegistryArticle,
        setShownRegistryArticle,
        addedDocumentType,
        setAddedDocumentType,
        fetchCheckCreateInventory,
        checkRegistryIsLoading,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  const onClose = useCallback(
    () => setFirstRegistryModalIsOpen(false),
    [setFirstRegistryModalIsOpen]
  );

  const onSave = useCallback(() => {
    if (!addedDocumentType) return;
    if (addedDocumentType === 4) {
      navigate("./act_service");
      onClose();
      return;
    }
    if (addedDocumentType === 5) {
      navigate("./advance_report_service");
      onClose();
      return;
    }
    if (addedDocumentType === 6) {
      navigate("./invoice/create");
      onClose();
      return;
    }
    if (addedDocumentType === 7) {
      navigate("./advance/create");
      onClose();
      return;
    }
    fetchCheckCreateInventory();
  }, [addedDocumentType, fetchCheckCreateInventory, navigate, onClose]);

  const onAddedDocumentTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setAddedDocumentType(e.target.value);
      setPickedRegistryArticle(null);
      setShownRegistryArticle("Выберите статью");
    },
    [setAddedDocumentType, setPickedRegistryArticle, setShownRegistryArticle]
  );

  return (
    <PopupModal
      title={"Добавить документ"}
      subtitle={"Выберите тип документа для добавления"}
      onSave={onSave}
      maxWidth={"440px"}
      buttonText={"Далее"}
      onClose={onClose}
      isButtonDisabled={!addedDocumentType || checkRegistryIsLoading}
    >
      <div
        style={{
          width: "100%",
          padding: "8px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Radio.Group onChange={onAddedDocumentTypeChange} value={addedDocumentType}>
          <Space direction="vertical">
            {/* <Radio data-testid={'input-order'} value={1}>Приходный кассовый ордер</Radio>
            <Radio data-testid={'output-order'} value={2}>Расходный кассовый ордер</Radio> */}
            <Radio data-testid={"inventory"} value={3}>
              Инвентаризация
            </Radio>
            <Radio data-testid={"act_service"} value={4}>
              Акт по услугам
            </Radio>
            <Radio data-testid={"advance_report_service"} value={5}>
              Авансовый отчет по услугам
            </Radio>
            <Radio data-testid={"invoice"} value={6}>
              Приходная накладная
            </Radio>
            <Radio data-testid={"advance"} value={7}>
              Авансовый отчет
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      {checkRegistryIsLoading && (
        //! Временный loader, поэтому стили прописаны сразу здесь
        <div style={{ alignSelf: "center", position: "absolute", right: "30px", bottom: "25px" }}>
          <div className="loader">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div className="loader-background"></div>
            </div>
          </div>
        </div>
      )}
    </PopupModal>
  );
});
