import classNames from "classnames";
import cls from "./styles.module.scss";
import { useMemo } from "react";

export interface RequiredFieldNames {
  title: string;
  id: number | string;
}

const defaultRequiredFieldNames: RequiredFieldNames = {
  title: "title",
  id: "id",
};

export interface TabButtonItem {
  id: number | string;
  title: string;
}

export interface Props<T> {
  currentTab: TabButtonItem | T;
  items: TabButtonItem[] | T[];
  onTabHandler: (item: TabButtonItem | T) => void;

  fieldNames?: Partial<RequiredFieldNames>;
}

export const Tabs = <T extends Object>(props: Props<T>) => {
  const { currentTab, items, onTabHandler, fieldNames } = props;

  const { title: titleName, id: idName } = useMemo(
    () => ({
      ...defaultRequiredFieldNames,
      ...fieldNames,
    }),
    [fieldNames]
  );

  const isActive = (id: number | string) => {
    return id === (currentTab as any)[idName] ? cls.active : "";
  };

  return (
    <ul className={cls.tabButtonList}>
      {items.map((item: any) => (
        <li key={item[idName]}>
          <button
            className={classNames(cls.tabButton, isActive(item[idName]))}
            onClick={() => onTabHandler(item)}
          >
            {item[titleName]}
          </button>
        </li>
      ))}
    </ul>
  );
};
