import { observer } from "mobx-react-lite";
import { FC } from "react";
import cls from "../../table-styles.module.scss";
import { IFinDividend } from "types/manager-reports";
import { CellValue } from "../cell-value";

interface RowProps {
  article?: IFinDividend;
}

const RowComponent: FC<RowProps> = ({ article }) => {
  if (!article)
    return (
      <tr className={cls["tr"]}>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
        <td className={`${cls["td"]}`}></td>
        <td className={`${cls["td"]}`}></td>
        <td className={`${cls["td"]}`}></td>
      </tr>
    );
  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}>{article.manager}</td>
      <td className={cls["td"]}>
        <CellValue articleCellValue={article.dividends} />
      </td>
      <td className={`${cls["td"]}`}>
        <CellValue articleCellValue={article.remainder} />
      </td>
      <td className={`${cls["td"]}`}></td>
      <td className={`${cls["td"]}`}></td>
    </tr>
  );
};

export const Row = observer(RowComponent);
