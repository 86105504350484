import { FC } from "react";

interface PlusIconProps {
  className?: string;
}

export const PlusIcon: FC<PlusIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.36276 7.375V0.5H8.61276V7.375H15.4878V8.625H8.61276V15.5H7.36276V8.625H0.487762V7.375H7.36276Z"
        fill={"#212129"}
      />
    </svg>
  );
};
