import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo, useState } from "react";
import cls from "./table-styles.module.scss";
import { NomenclatureRow } from "./row";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "ui-new/button";
import { PlusIcon } from "pages/registry-document/icons";
import { INomenclature, IUnit } from "types/registry-document";
import { getNomenclatures, getUnits } from "api/registryDocument";
import { getFormErrorMessage } from "utils/functions/table-error-message";

interface NomenclatureTableProps {
  allDisabled: boolean;
}

const NomenclatureTableComponent: FC<NomenclatureTableProps> = ({ allDisabled }) => {
  const [units, setUnits] = useState<IUnit[]>([]);
  const [nomenclatures, setNomenclatures] = useState<INomenclature[]>([]);

  useEffect(() => {
    getUnits().then(res => {
      if (Array.isArray(res)) setUnits(res);
      else setUnits([]);
    });
    getNomenclatures().then(res => {
      if (Array.isArray(res)) setNomenclatures(res);
      else setNomenclatures([]);
    });
  }, []);

  const { control, formState } = useFormContext<any>();
  const { fields, append, remove } = useFieldArray({ control, name: "positions" });

  const errorMessage = useMemo(
    () => getFormErrorMessage({ fields, errors: formState.errors, fieldName: "positions" }),
    [fields, formState.errors]
  );

  return (
    <>
      <div>
        <table className={cls["table"]}>
          <colgroup>
            <col width={32} />
            <col width={601} />
            <col width={134} />
            <col width={120} />
            <col width={80} />
            <col width={136} />
            <col width={40} />
          </colgroup>
          <thead className={cls["thead"]}>
            <tr className={cls["tr"]}>
              <th className={cls["th"]} style={{ textAlign: "center" }}>
                №
              </th>
              <th className={cls["th"]}>Номенклатура</th>
              <th className={cls["th"]}>Цена, ₽</th>
              <th className={cls["th"]}>Количество</th>
              <th className={cls["th"]}>Ед.изм</th>
              <th className={cls["th"]} colSpan={2}>
                Сумма, ₽
              </th>
            </tr>
          </thead>
          <tbody className={cls["tbody"]}>
            {fields.map((fieldProps, i, arr) => {
              return (
                <NomenclatureRow
                  key={fieldProps.id}
                  index={i}
                  remove={remove}
                  allDisabled={allDisabled}
                  lastIndex={arr.length - 1}
                  units={units}
                  nomenclatures={nomenclatures}
                />
              );
            })}
          </tbody>
        </table>
        {errorMessage && (
          <div style={{ marginTop: "8px" }}>
            <span className={cls["error-message"]}>{errorMessage}</span>
          </div>
        )}
      </div>
      <Button theme="outlined" className={cls["add-button"]} onClick={() => append({})}>
        <PlusIcon /> <span>Добавить строку</span>
      </Button>
    </>
  );
};

export const NomenclatureTable = observer(NomenclatureTableComponent);
