import classNames from "classnames";
import { forwardRef, type ButtonHTMLAttributes } from "react";
import cls from "./styles.module.scss";
import { BackIcon } from "./icon";

export type ButtonTheme = "primary" | "outlined" | "empty";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonTheme;
  isLoading?: boolean;
  className?: string;
  iconClassName?: string;
  onClick: (e?: any) => void;
}
export const BackButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    disabled = false,
    isLoading = false,
    type = "button",
    theme = "primary",
    children,
    className,
    iconClassName,
    onClick,
    ...otherProps
  } = props;

  return (
    <button
      ref={ref}
      disabled={disabled || isLoading}
      type={type}
      className={classNames(cls.button, cls[theme], className)}
      onClick={onClick}
      {...otherProps}
    >
      <BackIcon className={iconClassName} />
      {children}
    </button>
  );
});
