import { FC } from "react";
import cls from "./styles.module.scss";
import { PlusIcon } from "./icons";

interface LoadFileInputProps {
  id: string;
  title?: string;

  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

export const LoadFileInput: FC<LoadFileInputProps> = ({ id, title, inputProps }) => {
  return (
    <div className={cls["wrapper"]}>
      <label className={cls["label"]} htmlFor={id}>
        <PlusIcon className={cls["icon"]} />
        <span>{title ?? "Загрузить"}</span>
      </label>
      <input id={id} {...inputProps} className={cls["input"]} type="file" />
    </div>
  );
};
