import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import SelectField from "ui-new/select";
import { debounce } from "lodash";
import { Spin } from "antd";

const BUFFER_DISTANCE = 500;

interface ContragentProps {
  allDisabled: boolean;
}

const ContragentComponent: FC<ContragentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: {
        docType,
        fetchGetContragents,
        contragentsIsLoading,
        contragentsMeta,
        contragents,
      },
    },
  } = useStore();

  useEffect(() => {
    fetchGetContragents();
  }, []);

  const [searchTitle, setSearchTitle] = useState("");
  const debounceSearch = debounce((val: string) => {
    setSearchTitle(val);
    fetchGetContragents(val, 1);
  }, 300);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight <= BUFFER_DISTANCE && !contragentsIsLoading) {
        fetchGetContragents(searchTitle, (contragentsMeta?.current_page ?? 0) + 1);
      }
    },
    [contragentsIsLoading, contragentsMeta?.current_page, fetchGetContragents, searchTitle]
  );

  const { control, setValue } = useFormContext<{
    contragent_id: number;
    deal_id: number;
    deal: string;
  }>();

  const contragentsData = useMemo(() => {
    return contragents;
    // const haveContragent = contragents.some(c => currentDoc.contragent_id === c.id);
    // if (haveContragent) return contragents;

    // return [
    //   ...toJS(contragents),
    //   {
    //     id: currentDoc.contragent_id,
    //     title: currentDoc.contragent,
    //   },
    // ];
  }, [contragents]);

  return (
    <SelectField
      label={"Контрагент"}
      control={control}
      name={"contragent_id"}
      options={contragentsData}
      selectProps={{
        disabled: allDisabled,
        showSearch: true,
        optionFilterProp: "title",
        onSearch: debounceSearch,
        onPopupScroll: handleScroll,
        loading: contragentsIsLoading,
        placeholder: `Выбери контрагента`,
        fieldNames: {
          label: "title",
          value: "id",
        },
        onSelect: val => {
          const deal = contragents.find(c => c.id === val)?.deal;
          if (!deal) {
            setValue("deal", undefined as unknown as string);
            setValue("deal_id", undefined as unknown as number);
            return;
          }
          setValue("deal", deal.title);
          setValue("deal_id", deal.id);
        },
        dropdownRender: menu => (
          <>
            {menu}
            {contragentsIsLoading && (
              <div style={{ textAlign: "center", padding: "8px" }}>
                <Spin size="small" />
              </div>
            )}
          </>
        ),
      }}
    />
  );
};

export const ContragentField = observer(ContragentComponent);
