import { FieldErrors, FieldValues } from "react-hook-form";

interface ErrorMessageOptions<T extends FieldValues> {
  fields: T[];
  errors: FieldErrors<T>;
  fieldName: keyof T;
}

// Тип для объектов с сообщением об ошибке
type ErrorWithMessage = { message?: string };

export function getFormErrorMessage<T extends FieldValues>({
  fields,
  errors,
  fieldName,
}: ErrorMessageOptions<T>): string {
  if (fields.length === 0 || !errors[fieldName]) return "";

  const fieldErrors = errors[fieldName];

  // Обработка массива ошибок
  if (Array.isArray(fieldErrors)) {
    let message = fieldErrors.find(err => err?.message)?.message;
    if (message) return message;
  }

  // Обработка объекта ошибок
  if (fieldErrors && typeof fieldErrors === "object") {
    const entries = Object.entries(fieldErrors);

    if (entries.length > 0) {
      const [key, val] = entries[0];

      // Обработка корневой ошибки
      if (key === "root" && isErrorWithMessage(val)) {
        return val.message || "";
      }

      // Обработка вложенных ошибок
      if (isErrorObject(val)) {
        const nestedError = Object.values(val).find(isErrorWithMessage);
        return nestedError?.message || "";
      }
    }
  }

  return "Заполните таблицу или удалите лишние строки";
}

// Для проверки наличия message
const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return typeof error === "object" && error !== null && "message" in error;
};

// Для проверки вложенных ошибок
const isErrorObject = (error: unknown): error is Record<string, ErrorWithMessage> => {
  return typeof error === "object" && error !== null;
};
