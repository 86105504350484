import { IContragent, IDeal } from "pages/contracts/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./api-new";
import { IContragentMetaProps } from "./directories";

export async function getDeals() {
  try {
    const response = await api.get<IBaseResponse<IDeal[]>>("contragents/deals");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getContragents() {
  try {
    const response = await api.get<IBaseResponse<IContragent[]>>("contragents");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface GetContragentsPaginatedProps {
  page?: number;
  per_page?: number;
  search?: string;
}

export async function getContragentsPaginated(body: GetContragentsPaginatedProps) {
  try {
    const response = await api.get<
      IBaseResponse<{ data: IContragent[]; meta: IContragentMetaProps }>
    >("contragents/paginate", { params: body });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export interface IDealUpdateProps {
  contragent_id: number;
  type: "prepayment" | "postpayment";
  days: number | null;
}

export async function updateDeal(body: IDealUpdateProps) {
  try {
    const response = await api.post<IBaseResponse<IDeal>>("contragents/deals/update", body);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}
