import { api, IBaseResponse } from "./api";
import type { StockReport } from "../types/stock";
import { onError } from "./api-new";

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;
export const BASE_API_CONFIG = { baseURL: BASE_API_URL_V3 };

export interface getStockReportFilters {
  types: string[];
  //YYYY-MM-DD
  date: string;
}

export async function getStocksReport(body: getStockReportFilters) {
  try {
    const response = await api.post<IBaseResponse<StockReport[]>>(
      "/stock/stockReport",
      body,
      BASE_API_CONFIG
    );
    if (!response.data.success) throw new Error("Не удалось загрузить отчет за выбранную дату");
    return response.data.data;
  } catch (e) {
    throw onError(e);
  }
}

export async function getNomenclatureFilterTypes() {
  try {
    const response = await api.get("/directories/nomenclatures/types", BASE_API_CONFIG);
    return response.data.data;
  } catch {
    return null;
  }
}

export async function stockLoad() {
  return await api.post("/stock/stockLoad", {}, BASE_API_CONFIG);
}

export async function checkStocks() {
  try {
    const response = await api.post<{ success: boolean }>(
      "/stock/canStockLoad",
      {},
      BASE_API_CONFIG
    );
    return response.data;
  } catch {
    return null;
  }
}
