import {
  getAccessPeriod,
  getDirectoryContragentsPaginated,
  getEmployeesAndHelpers,
  getPnLTree,
  IContragentMetaProps,
} from "api";
import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "store/rootStore";
import { IContragent, IPnLArticle, IPnLArticleTree } from "types/directories";
import {
  DocType,
  DocumentStatus,
  IActService,
  IActServiceNewPosition,
  IActServicePosition,
  IAdvanceReportService,
  IAdvanceReportServiceNewPosition,
  IAdvanceReportServicePosition,
  ICreateActServiceProps,
  ICreateAdvanceReportServiceProps,
} from "types/registry-document";
import {
  createActDocumentPosition,
  deleteActDocumentPosition,
  getActService,
  getAdvanceReportService,
  getServicesPnLTree,
  updateActService,
  UpdateActServiceProps,
  updateActDocumentPosition,
  updateAdvanceReportService,
  UpdateAdvanceReportServiceProps,
  createAdvanceReportDocumentPosition,
  updateAdvanceReportDocumentPosition,
  deleteAdvanceReportDocumentPosition,
} from "api/registryServicesDocument";
import { createDocument, unApproveDocument } from "api/registryDocumentNew";
import { showNotificationModal } from "ui-new/alert";
import { fetchApproveStatus, fetchDeleteStatus } from "api/registryDocument";
import { showConfirmModal } from "ui-new/alert/confirm";
import { findFirstInTree } from "utils/functions/findInTree";
import { UTILITY_ARTICLE_HASH } from "utils/const/registry-document/utility_article";
import { IUser } from "pages/salary-fund/types";

export class RegistryServicesDocumentStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  docType: "act_service" | "advance_report_service" | null = null;

  act: IActService | null = null;
  actIsLoading: boolean = false;

  advanceReport: IAdvanceReportService | null = null;
  advanceReportIsLoading: boolean = false;

  //* Период выбора даты в дате проведения (сейчас убран, пока оставил)
  period: { period_start: string; period_end: string } | null = null;

  employees: IUser[] = [];
  contragents: IContragent[] = [];
  contragentsIsLoading = false;
  contragentsMeta: IContragentMetaProps | null = null;

  pnlArticles: IPnLArticleTree[] = [];
  utilityArticle: IPnLArticleTree | null = null;

  setDocType = (docType: "act_service" | "advance_report_service" | null) => {
    this.docType = docType;
  };
  setAct = (act: IActService | null) => {
    this.act = act;
  };

  setActIsLoading = (isLoading: boolean) => {
    this.actIsLoading = isLoading;
  };
  setAdvanceReport = (advanceReport: IAdvanceReportService | null) => {
    this.advanceReport = advanceReport;
  };

  setAdvanceReportIsLoading = (isLoading: boolean) => {
    this.advanceReportIsLoading = isLoading;
  };

  //* Период выбора даты в дате проведения (сейчас убран, пока оставил)
  fetchGetAccessPeriod = async () => {
    const response = await getAccessPeriod();
    if (!response) return null;
    this.period = response;
  };

  fetchGetContragents = async (searchValue?: string, page?: number) => {
    this.contragentsIsLoading = true;
    const response = await getDirectoryContragentsPaginated({
      per_page: 50,
      search: searchValue,
      page: page ?? this.contragentsMeta?.current_page,
    });
    if (!response) {
      this.contragentsIsLoading = false;
      return;
    }
    this.contragents =
      response.meta.current_page === 1
        ? response.data
        : [...toJS(this.contragents), ...response.data];
    this.contragentsMeta = response.meta;
    this.contragentsIsLoading = false;
  };

  fetchGetPnLArticles = async () => {
    const response = await getServicesPnLTree();
    if (!response) return;
    this.utilityArticle =
      findFirstInTree(
        response,
        article => article.filter_hash === UTILITY_ARTICLE_HASH,
        "children"
      ) ?? null;
    this.pnlArticles = response;
  };

  fetchGetPnLChildArticles = async (id: IPnLArticle["id"]) => {
    const response = await getPnLTree(id);
    if (!response) return [];
    return response;
  };

  fetchGetEmployeesAndHelpers = async () => {
    const response = await getEmployeesAndHelpers();
    if (!response) return;
    this.employees = response;
  };

  //#region Акт
  fetchGetAct = async (id: IActService["id"]) => {
    this.setActIsLoading(true);
    const response = await getActService(id);
    if (!response) {
      this.setActIsLoading(false);
      return;
    }
    this.setAct(response);
    this.setActIsLoading(false);
  };

  fetchCreateActWithPositions = async (
    act: ICreateActServiceProps["body"],
    positions: IActServiceNewPosition[] | IActServiceNewPosition
  ) => {
    this.setActIsLoading(true);
    const actResponse = await createDocument(DocType.act_service, act);
    if (!actResponse) {
      this.setActIsLoading(false);
      throw new Error("Не удалось создать акт по услугам");
    }
    if (!Array.isArray(positions)) {
      const positionResponse = await createActDocumentPosition({
        ...positions,
        act_service_id: actResponse.id,
      });
      if (!positionResponse) {
        showNotificationModal({
          title: "Акт успешно создан",
          type: "failure",
          errors: [
            "Произошла ошибка.",
            "Статья расходов не была записана, отредактируйте созданный акт",
          ],
        });
        this.setActIsLoading(false);
        return actResponse;
      }
      showNotificationModal({ title: "Акт успешно создан", type: "success" });
      this.setActIsLoading(false);
      return actResponse;
    }
    const promises: Promise<IActServicePosition | 0 | undefined>[] = [];
    positions.forEach(pos =>
      promises.push(createActDocumentPosition({ ...pos, act_service_id: actResponse.id }))
    );
    const results = await Promise.allSettled(promises);
    if (results.every(res => res.status === "fulfilled")) {
      showNotificationModal({ title: "Акт успешно создан", type: "success" });
      this.setActIsLoading(false);
      return actResponse;
    }
    showNotificationModal({
      title: "Акт успешно создан",
      type: "failure",
      errors: [
        `${results.filter(res => res.status === "fulfilled").length} статей успешно записано`,
        `${results.filter(res => res.status === "rejected").length} статей записать не удалось`,
      ],
    });
    this.setActIsLoading(false);
    return actResponse;
  };

  fetchUpdateActWithPositions = async (
    act: UpdateActServiceProps,
    positions: IActServiceNewPosition | IActServiceNewPosition[]
  ) => {
    this.setActIsLoading(true);
    const actResponse = await updateActService(act);
    if (!actResponse) {
      this.setActIsLoading(false);
      throw new Error("Не удалось изменить акт по услугам");
    }
    if (!Array.isArray(positions)) {
      const positionResponse = await updateActDocumentPosition({
        ...positions,
        id: actResponse.positions[0].id,
      });
      if (!positionResponse) {
        showNotificationModal({
          title: "Акт успешно изменен",
          type: "failure",
          errors: ["Произошла ошибка.", "Статья расходов не была изменена, попробуйте снова"],
        });
        this.setActIsLoading(false);
        throw new Error("Произошла ошибка. Статья расходов не была изменена, попробуйте снова");
      }
      //*Успешный выход
      return;
    }

    const promises: Promise<IActServicePosition | 0 | undefined | boolean>[] = [];
    positions.forEach((pos, i) => {
      const currentPost = actResponse.positions.length > i ? actResponse.positions[i] : null;

      if (currentPost) {
        promises.push(
          updateActDocumentPosition({ ...pos, id: currentPost.id, act_service_id: actResponse.id })
        );
        return;
      }
      promises.push(createActDocumentPosition({ ...pos, act_service_id: actResponse.id }));
    });
    if (actResponse.positions.length > positions.length) {
      actResponse.positions
        .slice(positions.length)
        .forEach(pos => promises.push(deleteActDocumentPosition(pos.id)));
    }
    const results = await Promise.allSettled(promises);
    if (results.every(res => res.status === "fulfilled")) {
      //*Успешный выход
      return;
    }
    showNotificationModal({
      title: "Акт успешно изменён",
      type: "failure",
      errors: [
        `${results.filter(res => res.status === "fulfilled").length} статей успешно изменено`,
        `${results.filter(res => res.status === "rejected").length} статей изменить не удалось`,
      ],
    });
    this.setActIsLoading(false);
    throw new Error("Произошла ошибка. Несколько статей расходов изменить не удалось");
  };

  approveStatusConfirm = async (
    docType: "act_service" | "advance_report_service",
    onConfirm: () => void,
    onBefore?: () => Promise<void>
  ) => {
    showConfirmModal({
      title: "Утвердить документ?",
      confirmButtonLabel: "Утвердить",
      onConfirm: () => {
        if (!!onBefore)
          return onBefore().then(() => this.fetchApproveStatus(docType).then(onConfirm));
        return this.fetchApproveStatus(docType).then(onConfirm);
      },
    });
  };

  private fetchApproveStatus = async (docType: "act_service" | "advance_report_service") => {
    if (docType === "act_service" && !this.act) return;
    if (docType === "advance_report_service" && !this.advanceReport) return;
    const currentId = docType === "act_service" ? this.act?.id : this.advanceReport?.id;
    if (!currentId) return;
    const response = await fetchApproveStatus(currentId, docType);
    if (!response) return;

    showNotificationModal({
      title: "Документ утвержден!",
      type: "success",
    });
    if (docType === "act_service") {
      this.setAct({
        ...toJS(this.act!),
        status: DocumentStatus.approved,
      });
      return;
    }
    this.setAdvanceReport({
      ...toJS(this.advanceReport!),
      status: DocumentStatus.approved,
    });
  };

  fetchDeleteStatus = async (
    comment: string,
    docType: "act_service" | "advance_report_service"
  ) => {
    if (docType === "act_service" && !this.act) return;
    if (docType === "advance_report_service" && !this.advanceReport) return;
    const currentId = docType === "act_service" ? this.act?.id : this.advanceReport?.id;
    if (!currentId) return;

    const response = await fetchDeleteStatus(comment, currentId, docType);
    if (!response) {
      showNotificationModal({ title: "Ошибка!", errors: ["Что-то пошло не так"], type: "failure" });
      return;
    }
    showNotificationModal({ title: "Документ удален!", type: "success" });
    return;
  };

  unApproveActConfirm = () => {
    showConfirmModal({
      title: "Распровести документ?",
      onConfirm: () => this.fetchUnApproveAct(),
      confirmButtonLabel: "Распровести",
      minWidth: "400px",
    });
  };
  private fetchUnApproveAct = async () => {
    if (!this.act) return;
    this.setActIsLoading(true);
    const response = await unApproveDocument(DocType.act_service, this.act.id);
    if (!response) {
      this.setActIsLoading(false);
      return;
    }
    this.setAct(response);
    this.setActIsLoading(false);
  };

  //#region Advance
  fetchGetAdvanceReport = async (id: IAdvanceReportService["id"]) => {
    this.setAdvanceReportIsLoading(true);
    const response = await getAdvanceReportService(id);
    if (!response) {
      this.setAdvanceReportIsLoading(false);
      return;
    }
    this.setAdvanceReport(response);
    this.setAdvanceReportIsLoading(false);
  };

  fetchCreateAdvanceReportWithPositions = async (
    advanceReport: ICreateAdvanceReportServiceProps["body"],
    positions: IAdvanceReportServiceNewPosition[] | IActServiceNewPosition
  ) => {
    this.setAdvanceReportIsLoading(true);
    const advanceReportResponse = await createDocument(
      DocType.advance_report_service,
      advanceReport
    );
    if (!advanceReportResponse) {
      this.setAdvanceReportIsLoading(false);
      throw new Error("Не удалось создать авансовый отчет по услугам");
    }
    if (!Array.isArray(positions)) {
      const positionResponse = await createAdvanceReportDocumentPosition({
        ...positions,
        advance_report_service_id: advanceReportResponse.id,
      });
      if (!positionResponse) {
        showNotificationModal({
          title: "Авансовый отчет успешно создан",
          type: "failure",
          errors: [
            "Произошла ошибка.",
            "Статья расходов не была записана, отредактируйте созданный авансовый отчет",
          ],
        });
        this.setAdvanceReportIsLoading(false);
        return advanceReportResponse;
      }
      showNotificationModal({ title: "Авансовый отчет успешно создан", type: "success" });
      this.setAdvanceReportIsLoading(false);
      return advanceReportResponse;
    }
    const promises: Promise<IAdvanceReportServicePosition | 0 | undefined>[] = [];
    positions.forEach(pos =>
      promises.push(
        createAdvanceReportDocumentPosition({
          ...pos,
          advance_report_service_id: advanceReportResponse.id,
        })
      )
    );
    const results = await Promise.allSettled(promises);
    if (results.every(res => res.status === "fulfilled")) {
      showNotificationModal({ title: "Авансовый отчет успешно создан", type: "success" });
      this.setAdvanceReportIsLoading(false);
      return advanceReportResponse;
    }
    showNotificationModal({
      title: "Авансовый отчет успешно создан",
      type: "failure",
      errors: [
        `${results.filter(res => res.status === "fulfilled").length} статей успешно записано`,
        `${results.filter(res => res.status === "rejected").length} статей записать не удалось`,
      ],
    });
    this.setAdvanceReportIsLoading(false);
    return advanceReportResponse;
  };

  fetchUpdateAdvanceReportWithPositions = async (
    advanceReport: UpdateAdvanceReportServiceProps,
    positions: IAdvanceReportServiceNewPosition[] | IAdvanceReportServiceNewPosition
  ) => {
    this.setAdvanceReportIsLoading(true);
    const advanceReportResponse = await updateAdvanceReportService(advanceReport);
    if (!advanceReportResponse) {
      this.setAdvanceReportIsLoading(false);
      throw new Error("Не удалось изменить авансовый отчет по услугам");
    }
    if (!Array.isArray(positions)) {
      const positionResponse = await updateAdvanceReportDocumentPosition({
        ...positions,
        id: advanceReportResponse.positions[0].id,
      });
      if (!positionResponse) {
        showNotificationModal({
          title: "Авансовый отчет успешно изменён",
          type: "failure",
          errors: [
            "Произошла ошибка.",
            "Статья расходов не была изменена, отредактируйте изменённый авансовый отчет",
          ],
        });
        this.setAdvanceReportIsLoading(false);
        throw new Error("Произошла ошибка. Статья расходов не была изменена");
      }
      //*Успешный выход
      return;
    }
    const promises: Promise<IAdvanceReportServicePosition | 0 | undefined | boolean>[] = [];
    positions.forEach((pos, i) => {
      const currentPost =
        advanceReportResponse.positions.length > i ? advanceReportResponse.positions[i] : null;
      if (!currentPost) return;
      promises.push(
        updateAdvanceReportDocumentPosition({
          ...pos,
          id: currentPost.id,
        })
      );
    });
    if (advanceReportResponse.positions.length > positions.length) {
      advanceReportResponse.positions.forEach((pos, i) => {
        if (positions.length > i) return;
        promises.push(deleteAdvanceReportDocumentPosition(pos.id));
      });
    }
    const results = await Promise.allSettled(promises);
    if (results.every(res => res.status === "fulfilled")) {
      //*Успешный выход
      return;
    }
    showNotificationModal({
      title: "Авансовый отчет успешно изменён",
      type: "failure",
      errors: [
        `${results.filter(res => res.status === "fulfilled").length} статей успешно записано`,
        `${results.filter(res => res.status === "rejected").length} статей записать не удалось`,
      ],
    });
    this.setAdvanceReportIsLoading(false);
    throw new Error("Произошла ошибка. Не удалось сохранить несколько статей расходов");
  };

  unApproveAdvanceReportConfirm = () => {
    showConfirmModal({
      title: "Распровести документ?",
      onConfirm: () => this.fetchUnApproveAdvanceReport(),
      confirmButtonLabel: "Распровести",
      minWidth: "400px",
    });
  };
  private fetchUnApproveAdvanceReport = async () => {
    if (!this.advanceReport) return;
    this.setAdvanceReportIsLoading(true);
    const response = await unApproveDocument(DocType.advance_report_service, this.advanceReport.id);
    if (!response) {
      this.setAdvanceReportIsLoading(false);
      return;
    }
    this.setAdvanceReport(response);
    this.setAdvanceReportIsLoading(false);
  };
  //#endregion
}
