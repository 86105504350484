import React, { useCallback, useRef } from "react";
import { toJS } from "mobx";
import { useStore } from "../../../store";
import { UpdatePositionTypes } from "../types";
import { observer } from "mobx-react-lite";

const TYPING_INTERVAL = 1000;

interface SumInputProps {
  value: any;
  index: number;

  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const SumInput: React.FC<SumInputProps> = ({ value, index, setIsEdit }) => {
  const timerRef = useRef<number | null>(null);
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: { advanceReport, setAdvanceReport, updatePosition },
  } = RootStore;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsEdit(prev => (!prev ? true : prev));
      const { value: val } = e.target;
      if (timerRef.current !== null) window.clearTimeout(timerRef.current);

      timerRef.current = window.setTimeout(() => {
        updatePosition(value.id, UpdatePositionTypes.SUM, +val).then(position => {
          const updatedPositions = [...advanceReport.positions];
          updatedPositions[index].sum = position.sum;
          setAdvanceReport({
            ...toJS(advanceReport),
            positions: updatedPositions,
          });
          setIsEdit(prev => (prev ? false : prev));
        });
      }, TYPING_INTERVAL);
    },
    [advanceReport, index, setAdvanceReport, setIsEdit, updatePosition, value.id]
  );

  return (
    <input
      autoFocus
      className={`registry-document__table-element input`}
      defaultValue={value.sum !== null ? value.sum : ""}
      onChange={onChange}
    />
  );
};

export default observer(SumInput);
