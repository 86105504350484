import { FC, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import cls from "./table-styles.module.scss";

const MAX_VALUE = 999999999.99;

interface InputInTableProps {
  name: string;
  control: any;
  type: string;
}

const formatMoney = (value: number) => {
  return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const InputInTable: FC<InputInTableProps> = ({ control, name, type }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur, ...field }, fieldState }) => {
        const numericValue = isNaN(value) ? 0 : value;

        return (
          <input
            {...field}
            className={`${cls["input"]} ${fieldState.error ? cls["inputError"] : ""}`}
            type="text"
            value={isEditing ? value : formatMoney(numericValue)} // Форматируем только когда не редактируем
            onChange={e => {
              // Заменяем запятую на точку
              let rawValue = e.target.value.replaceAll(",", ".");

              // Разрешаем точку только один раз
              const parts = rawValue.split(".");
              if (parts.length > 2) {
                rawValue = parts[0] + "." + parts[1]; // Если точек больше, то убираем лишние
              }

              // Ограничиваем два знака после точки
              if (parts.length === 2) {
                rawValue = `${parts[0]}.${parts[1].slice(0, 2)}`; // Оставляем максимум 2 знака после точки
              }

              onChange(rawValue);
            }}
            onFocus={e => {
              setIsEditing(true); // Переключаем в режим редактирования
              if (value === 0) onChange("");
              else e.target.value = numericValue.toString(); // Убираем форматирование
            }}
            onBlur={e => {
              setIsEditing(false); // Возвращаем форматирование
              let rawValue = e.target.value.replace(/[^\d.]/g, ""); // Убираем лишние символы
              if (rawValue === "") rawValue = "0"; // Если пустое поле, устанавливаем 0
              let finalValue = parseFloat(rawValue) || 0;

              // Проверяем на максимальное значение
              if (finalValue > MAX_VALUE) {
                finalValue = MAX_VALUE; // Если значение больше максимального, приравниваем к MAX_VALUE
              }

              onChange(finalValue); // Сохраняем в react-hook-form
            }}
          />
        );
      }}
    />
  );
};

interface PercentageInputInTableProps {
  name: string;
  control: any;
  inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

export const PercentageInputInTable: FC<PercentageInputInTableProps> = ({
  control,
  name,
  inputProps,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const nameSplitted = name.split(".");
  const parentName = nameSplitted.slice(0, -2);

  const { setValue, getValues } = useFormContext();

  const onPercentChange = (value: number) => {
    const parentItems = getValues(parentName.join("."));
    if (parentItems.length !== 2) return;
    const index = +nameSplitted[nameSplitted.length - 2];
    const nextIndex = index ? 0 : 1;
    setValue(parentName.join(".") + "." + nextIndex + ".percent", 100 - value);
  };

  return (
    <div className={cls["input-container"]}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState,
          formState: { errors },
        }) => {
          const parentError = parentName.reduce((acc, part) => {
            // Преобразуем индекс в число, если это индекс массива
            const key = isNaN(part as unknown as number) ? part : parseInt(part, 10);
            return acc ? acc[key] : undefined;
          }, errors as any);

          return (
            <input
              ref={ref}
              type="text"
              className={`${cls["input"]} ${
                parentError || fieldState.error ? cls["inputError"] : ""
              }`}
              value={isEditing ? value?.toString() || "" : `${value}%`} // Показываем % только когда не редактируем
              onChange={e => {
                const rawValue = e.target.value.replace(/\D/g, ""); // Убираем все, кроме цифр
                let newValue = rawValue ? Number(rawValue) : 0;
                if (newValue > 100) newValue = 100;
                if (newValue < 0) newValue = 0;
                onChange(newValue);
                onPercentChange(newValue);
              }}
              onFocus={e => {
                setIsEditing(true); // Убираем %
                if (value === 0) onChange(""); // Если 0, очищаем поле
              }}
              onBlur={e => {
                setIsEditing(false); // Возвращаем %
                const rawValue = e.target.value.replace(/\D/g, ""); // Оставляем только цифры
                const finalValue = rawValue ? Number(rawValue) : 0;
                onChange(finalValue); // Сохраняем число
              }}
              {...inputProps}
            />
          );
        }}
      />
    </div>
  );
};
