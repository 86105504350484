import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { IResponseWithErrors } from "./api";
import { ShowAlertPopupType } from "utils/const/shared";
import { showAlertPopup } from "ui/alert";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/api/v3/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

api.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${Cookies.get("token")}`;
  return request;
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  error => {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.dispatchEvent(new Event("storage"));
    } else {
      if (error.response.status === 403) {
        showAlertPopup(
          "Ошибка прав доступа",
          "Не соответствие прав доступа, невозможно выполнить операцию",
          ShowAlertPopupType.ERROR
        );
      }
    }
    return Promise.reject(error);
  }
);

export const onError = (error: any) => {
  const alertFields = {
    title: "Упс! Что-то пошло не так",
    subtitle: "Обновите страницу или попробуйте позже.",
    type: ShowAlertPopupType.ERROR,
  };

  if (axios.isAxiosError(error) && error.response) {
    if (error.response.data.message) {
      const errResponse = error.response.data as IResponseWithErrors;

      if (error.response.status === 403) {
        showAlertPopup(
          "Ошибка прав доступа",
          "Не соответствие прав доступа, невозможно выполнить операцию",
          ShowAlertPopupType.ERROR
        );
        return;
      }

      showAlertPopup(
        errResponse.message,
        errResponse.errors?.join("\n") ?? "",
        ShowAlertPopupType.ERROR
      );
      return;
    }
  }

  if (error instanceof Error) {
    if (error.name !== "TypeError") {
      showAlertPopup(
        error.message?.length > 0 ? error.message : "Что-то пошло не так",
        "",
        ShowAlertPopupType.ERROR
      );
      return;
    }
  }

  showAlertPopup(alertFields.title, alertFields.subtitle, alertFields.type);

  return 0;
};
