import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import SelectField from "ui-new/select";
import { toJS } from "mobx";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";
import { TransactionContext } from "../..";

interface PayOperationDdsTypesProps {
  allDisabled: boolean;
}

const PayOperationCashflowTypesComponent: FC<PayOperationDdsTypesProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: {
        payTypes,
        operationTypes,
        cashFlowArticles,
        formFields,
        transaction,
      },
    },
  } = useStore();

  const { isCash } = useContext(TransactionContext);

  const { watch, control, setValue, getValues, resetField } = useFormContext<{
    pay_type_id: number;
    operation_type_id: number;
    [MoneyTransactionDynamicFormFields.CashFlowArticleId]: number;
    cash_flow_articles: { cash_flow_article_id: number }[];
  }>();

  const payType = watch("pay_type_id");
  const cashFlowArticlesTable = watch("cash_flow_articles");

  const operationTypesData = useMemo(() => {
    return toJS(operationTypes).filter(
      op =>
        op.pay_type_id === payType &&
        (transaction
          ? op.money_transaction_type_id === transaction.type_id
          : op.money_transaction_type_code === "CASH") &&
        op.fields &&
        Object.keys(op.fields).length > 0
    );
  }, [operationTypes, payType, transaction]);

  const cashFlowArticlesData = useMemo(() => {
    return toJS(cashFlowArticles).sort((a, b) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });
  }, [cashFlowArticles]);

  useEffect(() => {
    const op_id = getValues("operation_type_id");
    if (operationTypesData.some(op => op.id === op_id)) return;
    resetField("operation_type_id");
  }, [operationTypesData]);

  const payTypeDisabled = useMemo(
    () => typeof transaction?.pay_type_id === "number",
    [transaction?.pay_type_id]
  );

  return (
    <>
      <SelectField
        label="Вид платежа"
        control={control}
        name="pay_type_id"
        options={payTypes}
        selectProps={{
          disabled: allDisabled || (isCash ? false : payTypeDisabled),
          showSearch: true,
          optionFilterProp: "title",
          placeholder: "Вид платежа",
          fieldNames: { label: "title", value: "id" },
        }}
      />
      <SelectField
        label="Вид операции"
        control={control}
        name="operation_type_id"
        options={operationTypesData}
        selectProps={{
          showSearch: true,
          optionFilterProp: "title",
          placeholder: "Вид операции",
          fieldNames: { label: "title", value: "id" },
          disabled: allDisabled,
        }}
      />
      {(!formFields || formFields.cash_flow_article_id?.show) && (
        <SelectField
          label="Статья ДДС"
          control={control}
          name={MoneyTransactionDynamicFormFields.CashFlowArticleId}
          options={cashFlowArticlesData}
          selectProps={{
            disabled: allDisabled || formFields?.cash_flow_article_id?.disabled,
            showSearch: true,
            optionFilterProp: "title",
            placeholder: "Название статьи",
            fieldNames: { label: "title", value: "id" },
          }}
        />
      )}
      {cashFlowArticlesTable?.length <= 1 &&
        (!formFields || formFields.cash_flow_articles_table?.show) && (
          <SelectField
            label="Статья ДДС"
            control={control}
            name={"cash_flow_articles.[0].cash_flow_article_id" as any}
            options={cashFlowArticlesData}
            selectProps={{
              disabled: allDisabled || formFields?.cash_flow_article_id?.disabled,
              showSearch: true,
              optionFilterProp: "title",
              placeholder: "Название статьи",
              fieldNames: { label: "title", value: "id" },
            }}
          />
        )}
    </>
  );
};

export const PayOperationCashflowTypes = observer(PayOperationCashflowTypesComponent);
