import { api } from "./api-new";
import { onError } from "./api-new";
import { IBaseResponse } from "./api";
import {
  DocType,
  UnApproveDocumentReturnType,
  CreateDocumentReturnType,
  CreateDocBodyProps,
} from "types/registry-document";

export const BASE_API_URL_V1 = `${process.env.REACT_APP_API_BASE}/api/v1/`;

//#region Создание документа
export async function createDocument<T extends DocType>(docType: T, body: CreateDocBodyProps[T]) {
  try {
    const response = await api.post<IBaseResponse<CreateDocumentReturnType[T]>>(
      `documents/${docType}/create`,
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function uploadFile(formData: FormData) {
  try {
    const response = await api.post<IBaseResponse<{ file_id: number; file_url: string }>>(
      `${BASE_API_URL_V1}file/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function unApproveDocument<T extends DocType>(docType: T, id: number) {
  try {
    const response = await api.post<IBaseResponse<UnApproveDocumentReturnType[T]>>(
      `documents/${docType}/unapprove`,
      { id }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateDocument<T extends DocType>(docType: T) {}
