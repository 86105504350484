import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import SelectField from "ui-new/select";
import { toJS } from "mobx";

interface ContragentProps {
  allDisabled: boolean;
}

const ContragentComponent: FC<ContragentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { fetchGetEmployeesAndHelpers, employees },
    },
  } = useStore();

  useEffect(() => {
    fetchGetEmployeesAndHelpers();
  }, []);

  const { control } = useFormContext<{
    user_contragent_id: number;
  }>();

  const contragentsData = useMemo(() => {
    return toJS(employees).map(e => ({ value: e.id, label: `${e.surname} ${e.name}` }));
    // const haveContragent = contragents.some(c => currentDoc.contragent_id === c.id);
    // if (haveContragent) return contragents;

    // return [
    //   ...toJS(contragents),
    //   {
    //     id: currentDoc.contragent_id,
    //     title: currentDoc.contragent,
    //   },
    // ];
  }, [employees]);

  return (
    <SelectField
      label={"Контрагент"}
      control={control}
      name={"user_contragent_id"}
      options={contragentsData}
      selectProps={{
        disabled: allDisabled,
        showSearch: true,
        optionFilterProp: "title",
        placeholder: `Выбери контрагента`,
      }}
    />
  );
};

export const ContragentField = observer(ContragentComponent);
