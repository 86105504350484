import dayjs from "dayjs";
import { Month } from "types/directories";
import { MONTHS_IN_YEAR } from "utils/const/shared/date";
import en from "dayjs/locale/en";

export function monthsMap(callback: (month: Month, i: number) => any) {
  return Array.from(Array(MONTHS_IN_YEAR).keys()).map((k, i) => {
    let month = dayjs().locale(en).month(k).format("MMM").toLowerCase();
    if (month === "jul") month = "july";
    return callback(month as Month, i);
  });
}
