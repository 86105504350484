import { FC, useMemo } from "react";
import { IBalanceArticle } from "types/manager-reports";
import cls from "../../table-styles.module.scss";
import { monthsMap } from "utils/functions/mapMonths";
import { CellValue } from "../cell-value";

interface RowProps {
  article?: IBalanceArticle;
}

const Row: FC<RowProps> = ({ article }) => {
  const monthsData = useMemo(() => {
    if (!article)
      return (
        <>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
        </>
      );
    return monthsMap(month => {
      const cellVal = article[month];
      if (!cellVal) return null;
      return (
        <td key={article.article + "-" + month} className={cls["td"]}>
          <CellValue articleCellValue={cellVal} />
        </td>
      );
    });
  }, [article]);

  if (!article)
    return (
      <tr className={cls["tr"]}>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
      </tr>
    );
  return (
    <tr className={`${cls["tr"]} ${article.has_children ? cls["highlight"] : ""}`}>
      <td className={cls["td"]}>{article.article}</td>
      {monthsData}
    </tr>
  );
};

export default Row;
