import { IArticleCellValue, IRevenueExpenseArticle } from "types/manager-reports";

const latinSymbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const mockRevenueExpenses: IRevenueExpenseArticle[] = [
  // Direct (20 элементов)
  {
    article: "Зарплата сотрудникам",
    type: "direct",
    plan: { value: 500000, symbol: "₽" },
    fact: { value: 550000, symbol: "₽" },
    deviation: { value: -50000, symbol: "₽" },
    positive: false,
  },
  {
    article: "Закупка материалов",
    type: "direct",
    plan: { value: 200000, symbol: "$" },
    fact: { value: 180000, symbol: "$" },
    deviation: { value: 20000, symbol: "$" },
    positive: true,
  },
  {
    article: "Логистика",
    type: "direct",
    plan: { value: null, symbol: "€" },
    fact: { value: 75000, symbol: "€" },
    deviation: { value: null, symbol: null },
    positive: null,
  },
  {
    article: "Реклама",
    type: "direct",
    plan: { value: "A", symbol: null },
    fact: { value: "B", symbol: null },
    deviation: { value: "C", symbol: null },
    positive: true,
  },

  // Revenue (10 элементов)
  {
    article: "Продажи онлайн",
    type: "revenue",
    plan: { value: 1200000, symbol: "₽" },
    fact: { value: 1350000, symbol: "₽" },
    deviation: { value: -150000, symbol: "₽" },
    positive: false,
  },
  {
    article: "Экспортные поставки",
    type: "revenue",
    plan: { value: null, symbol: null },
    fact: { value: 500000, symbol: "$" },
    deviation: { value: null, symbol: null },
    positive: null,
  },

  // Indirect (10 элементов)
  {
    article: "Амортизация",
    type: "indirect",
    plan: { value: 15000, symbol: "%" },
    fact: { value: 17000, symbol: "%" },
    deviation: { value: -2000, symbol: "%" },
    positive: false,
  },
  {
    article: "Налоговые платежи",
    type: "indirect",
    plan: { value: "X", symbol: null },
    fact: { value: "Y", symbol: null },
    deviation: { value: "Z", symbol: null },
    positive: true,
  },

  // Остальные элементы
  ...Array.from({ length: 32 }, (_, i) => {
    const type = i < 16 ? "direct" : i < 24 ? "revenue" : "indirect";
    const valueType = Math.random();

    const generateCell = (): IArticleCellValue => {
      if (valueType < 0.3) {
        return {
          value: Math.floor(Math.random() * 1000000),
          symbol: ["₽", "$", "%"][Math.floor(Math.random() * 3)],
        };
      }
      if (valueType < 0.6) {
        return {
          value: latinSymbols[Math.floor(Math.random() * 26)],
          symbol: null,
        };
      }
      return {
        value: null,
        symbol: Math.random() < 0.5 ? "₽" : null,
      };
    };

    return {
      article: `Статья ${i + 1}`,
      type,
      plan: generateCell(),
      fact: generateCell(),
      deviation: generateCell(),
      positive: Math.random() < 0.5,
    } as IRevenueExpenseArticle;
  }),
];
