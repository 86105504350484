import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useStore } from "store";
import cls from "../../table-styles.module.scss";
import { Row } from "./row";
import { toJS } from "mobx";

interface DividendsTableProps {}

const DividendsTableComponent: FC<DividendsTableProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { dividends },
    },
  } = useStore();

  const finActivitiesData = useMemo(() => {
    if (dividends === null || dividends.length === 0) return <Row />;
    return toJS(dividends).map((article, i) => <Row key={article.manager} article={article} />);
  }, [dividends]);

  return (
    <div>
      <table className={cls["table"]}>
        <colgroup>
          <col width={247} />
          <col width={303} />
          <col width={380} />
          <col width={190} />
          <col width={190} />
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={cls["th"]}>Партнер</th>
            <th className={cls["th"]}>Начисленные дивиденды за отчетный период</th>
            <th className={cls["th"]}>Остаток нераспределенный накопленным итогом</th>
            <th className={cls["th"]}>Дата выплаты</th>
            <th className={cls["th"]}>Сумма выплаты</th>
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>
          {finActivitiesData}
          <tr className={cls["tr"]}>
            <td className={cls["td"]}>Причина задолженности</td>
            <td colSpan={4} className={`${cls["td"]} ${cls["placeholder"]}`}></td>
          </tr>
          <tr className={cls["tr"]}>
            <td className={cls["td"]}>Задача по устранению задолженности</td>
            <td colSpan={4} className={`${cls["td"]} ${cls["placeholder"]}`}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const DividendsTable = observer(DividendsTableComponent);
