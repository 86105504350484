import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "../../table-styles.module.scss";
import { Row } from "./row";
import { IRevenueExpenseArticle } from "types/manager-reports";

interface RevenueExpensesTableProps {
  articles: IRevenueExpenseArticle[];
  title: string;
}

const RevenueExpensesTableComponent: FC<RevenueExpensesTableProps> = ({ articles, title }) => {
  const revenueExpensesData = useMemo(() => {
    if (!articles.length) return <Row />;
    return articles.map((article, i) => <Row key={article.article} article={article} />);
  }, [articles]);

  return (
    <div className={cls["table-container"]}>
      <h3 className={cls["table-title"]}>{title}</h3>
      <table className={cls["table"]}>
        <colgroup>
          <col width={601} />
          <col width={176} />
          <col width={176} />
          <col width={211} />
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={cls["th"]}>Статья</th>
            <th className={cls["th"]}>План</th>
            <th className={cls["th"]}>Факт</th>
            <th className={cls["th"]}>Отклонение</th>
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>{revenueExpensesData}</tbody>
      </table>
    </div>
  );
};

export const RevenueExpensesTable = observer(RevenueExpensesTableComponent);
