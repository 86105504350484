import { observer } from "mobx-react-lite";
import { FC } from "react";
import { InvoiceHeader } from "./header";
import cls from "./styles.module.scss";
import { FormProvider, useForm } from "react-hook-form";
import formCls from "../../services/styles.module.scss";
import {
  ContragentField,
  DateFields,
  SumField,
  NomenclatureTable,
  ResponsibleField,
} from "./components";
import dayjs from "dayjs";
import { createDocument } from "api/registryDocumentNew";
import { DocType, IPositionNew } from "types/registry-document";
import { useNavigate } from "react-router";
import { schema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Files } from "./components/files";

export interface FormValues {
  user_contragent_id: number;
  //YYYY-MM-DD
  date: string;
  files?: { id: number }[];
  positions: IPositionNew[];
}

interface AdvanceCreateProps {}

const AdvanceCreateComponent: FC<AdvanceCreateProps> = () => {
  const allDisabled = false;
  const navigate = useNavigate();
  const methods = useForm<FormValues & { created_date: string; sum: number }>({
    defaultValues: { created_date: dayjs().format("YYYY-MM-DD"), positions: [{}] },
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: FormValues) => {
    let files: any[] = data.files ?? [];
    if (files.length > 0) {
      files = files.map(file => file.id);
    }
    const response = await createDocument(DocType.advances, { ...data, files });
    if (!response) return;
    navigate(`..#${response.id}`);
  };

  return (
    <FormProvider {...methods}>
      <div className={cls["wrapper"]}>
        <InvoiceHeader
          isCreate
          onSave={handleSubmit(onSubmit, e => {
            console.log(e);
          })}
        />
        <div
          className={formCls["form-container"]}
          style={{ marginTop: "8px", marginBottom: "-19px" }}
        >
          <div className={formCls["form-column"]}>
            <div className={formCls["row"]}>
              <DateFields allDisabled={allDisabled} />
            </div>
            <SumField allDisabled={allDisabled} />
          </div>
          <div className={formCls["form-column"]}>
            <ResponsibleField allDisabled={allDisabled} />
            <ContragentField allDisabled={allDisabled} />
          </div>
        </div>
        <Files />
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <NomenclatureTable allDisabled={allDisabled} />
        </div>
      </div>
    </FormProvider>
  );
};

export const AdvanceCreate = observer(AdvanceCreateComponent);
