import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "./styles.module.scss";
import { BucketIcon } from "pages/money-transactions/money-transaction-edit/icons";
import { Control, Controller } from "react-hook-form";
import { IActServiceNewPosition } from "types/registry-document";
import { useStore } from "store";

const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;

  if (value.includes(".")) {
    const [integer, decimal] = value.split(".");
    if (decimal.length > 1) {
      e.target.value = `${integer}.${decimal.substring(0, 2)}`;
    }
  }
};

interface PositionRowProps {
  position: IActServiceNewPosition;
  control: Control<any>;
  remove: (index: number) => void;
  index: number;
  allDisabled: boolean;
  lastIndex: number;
}

const PositionRowComponent: FC<PositionRowProps> = ({
  control,
  remove,
  index,
  position,
  allDisabled,
  lastIndex,
}) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: { act },
    },
  } = useStore();

  const positionFieldsDisabled = useMemo(
    () => (["approve"] as any[]).includes(act?.status),
    [act?.status]
  );

  return (
    <tr className={cls["tr"]}>
      <td
        className={`${cls["td"]} ${allDisabled || positionFieldsDisabled ? cls["disabled"] : ""}`}
      >
        {(position as any).pnl_article_title}
      </td>
      <td className={cls["td"]}>
        <Controller
          name={`positions.${index}.sum`}
          control={control}
          render={({ field }) => (
            <input
              className={`${cls["input-sum"]} ${
                allDisabled || positionFieldsDisabled ? cls["disabled"] : ""
              }`}
              {...field}
              type="number"
              placeholder="Введите сумму"
              disabled={allDisabled}
              onInput={onInput}
            />
          )}
        ></Controller>
      </td>
      <td
        className={`${cls["td"]} ${
          allDisabled || positionFieldsDisabled || lastIndex === 0 ? cls["disabled"] : ""
        }`}
      >
        <button
          className={cls["bucket-button"]}
          disabled={allDisabled || lastIndex === 0}
          onClick={() => remove(index)}
        >
          <BucketIcon color="#EB5757" />
        </button>
      </td>
    </tr>
  );
};

export const PositionRow = observer(PositionRowComponent);
