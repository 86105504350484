import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import { EInputStyleVariant, Input } from "ui-new/input";
import SelectField from "ui-new/select";
import { MoneyTransactionDynamicFormFields } from "types/money-transactions";
import { toJS } from "mobx";
import { debounce } from "lodash";
import { Spin } from "antd";

const BUFFER_DISTANCE = 500;

enum ContragentTypeEnum {
  "contragent" = 0,
  "user",
  "business_holder",
}

interface ContragentProps {
  allDisabled: boolean;
}

const ContragentComponent: FC<ContragentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: {
        transaction,
        contragents,
        fetchGetContragents,
        formFields,
        contragentsIsLoading,
        contragentsMeta,
        employees,
        businessHolders,
      },
    },
  } = useStore();
  const [searchTitle, setSearchTitle] = useState("");
  const debounceSearch = debounce((val: string) => {
    setSearchTitle(val);
    fetchGetContragents(val, 1);
  }, 300);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight <= BUFFER_DISTANCE && !contragentsIsLoading) {
        fetchGetContragents(searchTitle, (contragentsMeta?.current_page ?? 0) + 1);
      }
    },
    [contragentsIsLoading, contragentsMeta?.current_page, fetchGetContragents, searchTitle]
  );

  useEffect(() => {
    return () => {
      resetField("contragent_id");
      resetField("contragent_inn");
      resetField("deal_title");
    };
  }, []);

  const { control, resetField } = useFormContext<{
    [MoneyTransactionDynamicFormFields.ContragentId]: number;
    contragent_inn: number;
    deal_title: string;
    operation_type_id: number;
  }>();

  const employeesData = useMemo(() => {
    const employeesData = toJS(employees).map(emp => ({
      value: emp.id,
      label: `${emp.surname} ${emp.name}`,
    }));
    if (!transaction?.contragent_id) return employeesData;

    const haveEmployee = employees.some(e => e.id === transaction.contragent_id);
    if (haveEmployee) return employeesData;

    return [...employeesData, { value: transaction.id, label: transaction.contragent_title }];
  }, [employees, transaction]);

  const businessHoldersData = useMemo(() => {
    const businessHoldersData = toJS(businessHolders).map(bHolder => ({
      value: bHolder.id,
      label: `${bHolder.last_name} ${bHolder.first_name}`,
    }));

    if (!transaction?.contragent_id || transaction.contragent_type !== ContragentTypeEnum[2])
      return businessHoldersData;

    const haveEmployee = businessHolders.some(e => e.id === transaction.contragent_id);
    if (haveEmployee) return businessHoldersData;

    return [...businessHoldersData, { value: transaction.id, label: transaction.contragent_title }];
  }, [businessHolders, transaction]);

  const contragentsData = useMemo(() => {
    if (!transaction?.contragent_id) return contragents;

    const haveContragent = contragents.some(c => c.id === transaction.contragent_id);
    if (haveContragent) return contragents;

    return [
      ...toJS(contragents),
      {
        id: transaction.contragent_id,
        title: transaction.contragent_title ?? "",
        inn: transaction.contragent_inn,
      },
    ];
  }, [contragents, transaction]);

  const contragentFieldDisabled = useMemo(
    () => formFields?.contragent_id?.disabled,
    [formFields?.contragent_id?.disabled]
  );

  const contragentField = useMemo(() => {
    switch (formFields?.contragent_type?.default) {
      case 1:
        return (
          <SelectField
            label={"Физ. лицо"}
            control={control}
            name={MoneyTransactionDynamicFormFields.ContragentId}
            options={employeesData}
            selectProps={{
              placeholder: "Выберите работника",
              disabled: allDisabled || contragentFieldDisabled,
              showSearch: true,
              fieldNames: {
                label: "label",
                value: "value",
              },
            }}
          />
        );
      case 2:
        return (
          <SelectField
            label={"Фин. партнер"}
            control={control}
            name={MoneyTransactionDynamicFormFields.ContragentId}
            options={businessHoldersData}
            selectProps={{
              placeholder: "Выберите фин партнера",
              disabled: allDisabled || contragentFieldDisabled,
              showSearch: true,
              fieldNames: {
                label: "label",
                value: "value",
              },
            }}
          />
        );
      default:
        return (
          <SelectField
            label={"Контрагент"}
            control={control}
            name={MoneyTransactionDynamicFormFields.ContragentId}
            options={contragentsData}
            selectProps={{
              placeholder: "Выберите контрагента",
              disabled: allDisabled || contragentFieldDisabled,
              showSearch: true,
              optionFilterProp: "title",
              onSearch: debounceSearch,
              onPopupScroll: handleScroll,
              loading: contragentsIsLoading,
              fieldNames: {
                label: "title",
                value: "id",
              },
              dropdownRender: menu => (
                <>
                  {menu}
                  {contragentsIsLoading && (
                    <div style={{ textAlign: "center", padding: "8px" }}>
                      <Spin size="small" />
                    </div>
                  )}
                </>
              ),
            }}
          />
        );
    }
  }, [
    allDisabled,
    businessHoldersData,
    contragentFieldDisabled,
    contragentsData,
    contragentsIsLoading,
    control,
    debounceSearch,
    employeesData,
    formFields?.contragent_type?.default,
    handleScroll,
  ]);

  return (
    <>
      {contragentField}
      <Input
        label="ИНН"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="text"
        name="contragent_inn"
        inputProps={{
          disabled: true,
        }}
      />
      <Input
        label="Номер договора"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="text"
        name="deal_title"
        inputProps={{
          disabled: true,
        }}
      />
    </>
  );
};

export const Contragent = observer(ContragentComponent);
