import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cls from "./table-styles.module.scss";
import { BucketIcon } from "pages/registry-document/icons";
import SelectField from "ui-new/select";
import { Controller, useFormContext } from "react-hook-form";
import { addSpaces } from "assets/utils";
import { INomenclature, IUnit } from "types/registry-document";

const onInput = (e: React.FormEvent<HTMLInputElement>, length: number = 2) => {
  const { value } = e.target as HTMLInputElement;

  if (value.includes(".")) {
    const [integer, decimal] = value.split(".");
    if (decimal.length > 1) {
      (e.target as HTMLInputElement).value = `${integer}.${decimal.substring(0, length)}`;
    }
  }
};

interface NomenclatureRowProps {
  index: number;
  remove: (index: number) => void;
  allDisabled: boolean;
  lastIndex: number;
  units: IUnit[];
  nomenclatures: INomenclature[];
}

const NomenclatureRowComponents: FC<NomenclatureRowProps> = ({
  allDisabled,
  lastIndex,
  index,
  remove,
  units,
  nomenclatures,
}) => {
  const [selectedNomenclature, setSelectedNomenclature] = useState<INomenclature | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const valueRef = useRef<HTMLInputElement>(null);
  const sumRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputValue = valueRef.current;
    const inputSum = sumRef.current;
    const handleWheelInputVal = (e: WheelEvent) => {
      if (document.activeElement === valueRef.current) {
        e.preventDefault(); // Запрещаем изменение значения
      }
    };
    const handleWheelInputSum = (e: WheelEvent) => {
      if (document.activeElement === sumRef.current) {
        e.preventDefault(); // Запрещаем изменение значения
      }
    };

    inputValue?.addEventListener("wheel", handleWheelInputVal, { passive: false });
    inputSum?.addEventListener("wheel", handleWheelInputSum, { passive: false });

    return () => {
      inputValue?.removeEventListener("wheel", handleWheelInputVal);
      inputSum?.removeEventListener("wheel", handleWheelInputSum);
    };
  }, []);

  const { control, getFieldState, getValues, setValue } = useFormContext<any>();

  useEffect(() => {
    const unit = units.find(u => u.title === selectedNomenclature?.unit);
    if (!unit) return;
    setValue(`positions.${index}.unit_id`, unit.id);
  }, [index, selectedNomenclature, setValue, units]);

  const idState = getFieldState(`positions.${index}.nomenclature_id`);

  const disabled = useMemo(() => allDisabled || lastIndex === 0, [allDisabled, lastIndex]);

  const onPriceChange = useCallback(
    (type: "value" | "sum", e: React.FormEvent<HTMLInputElement>) => {
      const value = Number((e.target as HTMLInputElement).value);
      if (isNaN(value) || (e.target as HTMLInputElement).value.length === 0 || value === 0) {
        setPrice(null);
        return;
      }
      if (type === "value") {
        const sum = Number(getValues(`positions[${index}].sum`));
        if (!isNaN(sum) && sum !== 0) {
          console.log(value);

          setPrice(sum / value);
        }
        return;
      }
      if (type === "sum") {
        const val = Number(getValues(`positions[${index}].value`));
        if (!isNaN(val) && val !== 0) {
          setPrice(value / val);
        }
        return;
      }
    },
    [getValues, index]
  );

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]} style={{ textAlign: "center" }}>
        {index + 1}
      </td>
      <td className={cls["td"]}>
        <SelectField
          className={`${cls["article-selector"]} ${idState.error ? cls["error"] : ""}`}
          control={control}
          name={`positions.${index}.nomenclature_id`}
          options={nomenclatures}
          showError={false}
          selectProps={{
            onSelect: val => {
              setSelectedNomenclature(nomenclatures.find(n => n.id === val) ?? null);
            },
            fieldNames: {
              label: "name",
              value: "id",
            },
            placeholder: "Выберите номенклатуру",
            showSearch: true,
            optionFilterProp: "name",
            disabled: allDisabled,
          }}
        />
      </td>
      <td className={cls["td"]}>
        {typeof price === "number" ? addSpaces(price.toFixed(2)) : price}
      </td>
      <td className={cls["td"]}>
        <Controller
          name={`positions.${index}.value`}
          control={control}
          render={({ field, fieldState }) => {
            field.ref(valueRef);
            return (
              <input
                className={`${cls["input-sum"]} ${fieldState.error ? cls["error"] : ""} ${
                  allDisabled ? cls["disabled"] : ""
                }`}
                {...field}
                ref={valueRef}
                type="number"
                placeholder="Укажи количество"
                disabled={allDisabled}
                onInput={e => {
                  onInput(e, 3);
                  onPriceChange("value", e);
                }}
              />
            );
          }}
        ></Controller>
      </td>
      <td className={cls["td"]}>{selectedNomenclature?.unit}</td>
      <td className={cls["td"]}>
        <Controller
          name={`positions.${index}.sum`}
          control={control}
          render={({ field, fieldState }) => {
            field.ref(sumRef);
            return (
              <input
                className={`${cls["input-sum"]} ${fieldState.error ? cls["error"] : ""} ${
                  allDisabled ? cls["disabled"] : ""
                }`}
                {...field}
                ref={sumRef}
                type="number"
                placeholder="Укажи сумму"
                disabled={allDisabled}
                onInput={e => {
                  onInput(e);
                  onPriceChange("sum", e);
                }}
              />
            );
          }}
        ></Controller>
      </td>
      <td className={`${cls["td"]} ${disabled ? cls["disabled"] : ""}`}>
        <button className={cls["bucket-button"]} disabled={disabled} onClick={() => remove(index)}>
          <BucketIcon color={disabled ? "#999999" : "#EB5757"} />
        </button>
      </td>
    </tr>
  );
};

export const NomenclatureRow = observer(NomenclatureRowComponents);
