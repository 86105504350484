import { FC, useEffect, useMemo } from "react";
import cls from "../styles.module.scss";
import {
  DateAndNumber,
  SourceField,
  PayOperationCashflowTypes,
  Contragent,
  SumField,
  CommissionField,
  Description,
  PnLField,
  Tax,
  EmployeesTable,
  CashFlowArticlesTable,
} from "./components";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { TaxTypeIdPeriodCommitmentFields } from "./components/tax-type";

interface FormProps {}

const FormComponent: FC<FormProps> = () => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields, transaction },
    },
  } = useStore();
  const allDisabled = useMemo(
    () =>
      (["Удалено", "В процессе утверждения", "Утверждено"] as any[]).includes(
        transaction?.status_title
      ),
    [transaction]
  );

  return (
    <>
      <div className={cls["form-container"]}>
        <div className={cls["form-column"]}>
          <DateAndNumber allDisabled={allDisabled} />
          <SourceField allDisabled={allDisabled} />
          <PayOperationCashflowTypes allDisabled={allDisabled} />
          {formFields?.pnl_article_id?.show && <PnLField allDisabled={allDisabled} />}
        </div>
        <div className={cls["form-column"]}>
          {(!formFields || formFields.contragent_id?.show) && (
            <Contragent allDisabled={allDisabled} />
          )}
          <SumField allDisabled={allDisabled} />
          {formFields?.commission?.show && <CommissionField allDisabled={allDisabled} />}
          <Tax allDisabled={allDisabled} />
          <TaxTypeIdPeriodCommitmentFields allDisabled={allDisabled} />
        </div>
      </div>
      <Description />
      {formFields?.users_table?.show && <EmployeesTable allDisabled={allDisabled} />}
      {formFields?.cash_flow_articles_table?.show && (
        <CashFlowArticlesTable allDisabled={allDisabled} />
      )}
    </>
  );
};

export const Form = observer(FormComponent);
