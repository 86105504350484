import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "../../table-styles.module.scss";
import { Row } from "./row";
import { monthsMap } from "utils/functions/mapMonths";
import { useStore } from "store";
import dayjs from "dayjs";
import { toJS } from "mobx";

interface FinResultTableProps {}

const FinResultTableComponent: FC<FinResultTableProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { finResults },
    },
  } = useStore();

  const monthsData = useMemo(() => {
    const months = monthsMap((month, i) => {
      if (!finResults || !finResults[0]) return null;
      if (finResults[0][month])
        return (
          <th
            key={"fin-results-" + month}
            className={cls["th"]}
            style={{ textTransform: "capitalize" }}
          >
            {dayjs().month(i).format("MMMM")}
          </th>
        );
      return null;
    });
    if (months.every(m => m === null)) {
      const now = dayjs();
      return months.map((m, i) => {
        if ([now.add(-2, "month").month(), now.add(-1, "month").month()].includes(i))
          return (
            <th
              key={"fin-results-" + i}
              className={cls["th"]}
              style={{ textTransform: "capitalize" }}
            >
              {dayjs().month(i).format("MMMM")}
            </th>
          );
        return null;
      });
    }
    if (months[0] && months[months.length - 1]) return months.reverse();
    return months;
  }, [finResults]);

  const finResultsData = useMemo(() => {
    if (finResults === null || finResults.length === 0) return <Row index={0} />;
    return toJS(finResults).map((article, i) => (
      <Row key={article.name} index={i} article={article} />
    ));
  }, [finResults]);

  return (
    <div>
      <table className={cls["table"]}>
        <colgroup>
          <col width={40} />
          <col width={424} />
          {monthsData.map(m => m && <col key={m.key} width={278} />)}
          <col width={278} />
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={cls["th"]}></th>
            <th className={cls["th"]}>Статья</th>
            {monthsData}
            <th className={cls["th"]}>Динамика</th>
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>{finResultsData}</tbody>
      </table>
    </div>
  );
};

export const FinResultTable = observer(FinResultTableComponent);
