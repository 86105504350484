import { FC } from "react";
import cls from "../styles.module.scss";
import { ApproveArrowIcon, BucketIcon } from "pages/registry-document/icons";
import { DocumentStatus } from "types/registry-document";

interface DocumentStatusProps {
  status: DocumentStatus;
}

const DocumentStatusComponent: FC<DocumentStatusProps> = ({ status }) => {
  if (status === DocumentStatus.approved)
    return (
      <div className={`${cls["document-status"]} ${cls["approved"]}`}>
        <ApproveArrowIcon />
        <span>Документ утвержден</span>
      </div>
    );

  if (status === DocumentStatus.deleted)
    return (
      <div className={`${cls["document-status"]} ${cls["deleted"]}`}>
        <BucketIcon width={"16px"} height={"16px"} />
        <span>Документ удален</span>
      </div>
    );
  return null;
};

export const DocumentStatusContainer = DocumentStatusComponent;
