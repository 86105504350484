import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useStore } from "store";
import { BalanceTable } from "./table";
import cls from "../../styles.module.scss";
import { toJS } from "mobx";

interface RevenueExpensesTabProps {}

const BalanceTabComponent: FC<RevenueExpensesTabProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { balance },
    },
  } = useStore();

  const [active, passive, delta] = useMemo(() => {
    if (!balance) return [[], [], []];
    const { active, passive, delta } = toJS(balance);
    return [active, passive, delta];
  }, [balance]);

  return (
    <div className={cls["tables-wrapper"]}>
      <BalanceTable title="Актив" articles={active} />
      <BalanceTable title="Пассив" articles={passive} />
      <BalanceTable title="Дельта Баланса" articles={delta} />
    </div>
  );
};

export const BalanceTab = observer(BalanceTabComponent);
