import { FC, useMemo } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";
import SelectField from "ui-new/select";
import cls from "./styles.module.scss";
import { BucketIcon } from "pages/money-transactions/money-transaction-edit/icons";
import { FormValues } from "pages/money-transactions/money-transaction-edit/types";

interface CashFlowArticleRowProps {
  index: number;
  remove: (index: number) => void;
  allDisabled: boolean;
  control: Control<any>;
  lastIndex: number;

  cashFlowArticlesData: {
    label: string;
    value: number;
  }[];
}

const CashFlowArticleRowComponent: FC<CashFlowArticleRowProps> = ({
  index,
  remove,
  allDisabled,
  control,
  lastIndex,
  cashFlowArticlesData,
}) => {
  console.log();

  const { getFieldState } = useFormContext<FormValues>();

  const idState = getFieldState(`cash_flow_articles.${index}.cash_flow_article_id`);

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}>
        <SelectField
          className={`${cls["article-selector"]} ${idState.error ? cls["error"] : ""}`}
          control={control}
          name={`cash_flow_articles.${index}.cash_flow_article_id`}
          options={cashFlowArticlesData}
          showError={false}
          selectProps={{
            placeholder: "Выберите статью ДДС",
            showSearch: true,
            optionFilterProp: "label",
            disabled: allDisabled,
          }}
        />
      </td>
      <td className={cls["td"]}>
        <Controller
          name={`cash_flow_articles.${index}.amount`}
          control={control}
          render={({ field, fieldState }) => (
            <input
              className={`${cls["input-sum"]} ${fieldState.error ? cls["error"] : ""} ${
                allDisabled ? cls["disabled"] : ""
              }`}
              {...field}
              type="number"
              placeholder="Укажите сумму"
              disabled={allDisabled}
            />
          )}
        ></Controller>
      </td>
      <td className={`${cls["td"]} ${allDisabled || lastIndex === 0 ? cls["disabled"] : ""}`}>
        <button
          className={cls["bucket-button"]}
          disabled={allDisabled || lastIndex === 0}
          onClick={() => remove(index)}
        >
          <BucketIcon color={allDisabled ? "#999999" : "#EB5757"} />
        </button>
      </td>
    </tr>
  );
};

export const CashFlowArticleRow = CashFlowArticleRowComponent;
