import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";
import cls from "../../services/styles.module.scss";
import { useNavigate } from "react-router";
import { Button } from "ui-new/button";
import { BackButton } from "ui-new/back-button";

interface TransactionHeaderProps {
  onSave: () => void;
  isCreate: boolean;
}

const InvoiceHeaderComponent: FC<TransactionHeaderProps> = ({ isCreate, onSave }) => {
  const navigate = useNavigate();

  const buttonsData = useMemo(() => {
    return (
      <div className={cls["buttons-group"]}>
        <Button onClick={onSave}>Сохранить документ</Button>
      </div>
    );
  }, [onSave]);

  const title = useMemo(() => {
    if (isCreate) return "Создание документа: Авансовый отчет";
    return "Авансовый отчет";
  }, [isCreate]);

  const onBackButton = useCallback(() => {
    navigate("../..");
  }, [navigate]);

  return (
    <>
      <div className={cls["header"]}>
        <div className={cls["breadcrumbs"]}>
          <p className={cls["grey"]} onClick={() => navigate("/main/registry/")}>
            Реестр документов /
          </p>
          <p className={cls["black"]}>Авансовый отчет</p>
        </div>
        <div className={cls["title-container"]}>
          <div className={cls["text"]}>
            <BackButton style={{ alignSelf: "flex-start" }} onClick={onBackButton} />
            <h2 className={cls["title"]}>{title}</h2>
          </div>
          {buttonsData}
        </div>
      </div>
    </>
  );
};

export const InvoiceHeader = observer(InvoiceHeaderComponent);
