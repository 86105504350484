import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useStore } from "store";
import { IRevenueExpenseArticle } from "types/manager-reports";
import { RevenueExpensesTable } from "./table";
import cls from "../../styles.module.scss";

interface RevenueExpensesTabProps {}

const RevenueExpensesTabComponent: FC<RevenueExpensesTabProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { revenueExpenses },
    },
  } = useStore();

  const [revenueData, directData, indirectData] = useMemo(() => {
    const revenueData: IRevenueExpenseArticle[] = [];
    const directData: IRevenueExpenseArticle[] = [];
    const indirectData: IRevenueExpenseArticle[] = [];
    if (revenueExpenses === null) return [revenueData, directData, indirectData];

    toJS(revenueExpenses).forEach(article => {
      switch (article.type) {
        case "direct":
          directData.push(article);
          break;
        case "indirect":
          indirectData.push(article);
          break;
        default:
          revenueData.push(article);
          break;
      }
    });

    return [revenueData, directData, indirectData];
  }, [revenueExpenses]);

  return (
    <div className={cls["tables-wrapper"]}>
      <RevenueExpensesTable title="Выручка" articles={revenueData} />
      <RevenueExpensesTable title="Прямые затраты" articles={directData} />
      <RevenueExpensesTable title="Косвенные затраты" articles={indirectData} />
    </div>
  );
};

export const RevenueExpensesTab = observer(RevenueExpensesTabComponent);
