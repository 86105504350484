import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toJS } from "mobx";
import { EmptyTableBody } from "../../../ui/empty-table-body";
import { useStore } from "../../../store";
import { Select, Tooltip } from "antd";
import { Button, EButtonVariant } from "../../../ui";
import { observer } from "mobx-react-lite";
import { BucketIcon } from "../icons";
import { type Nomenclature, UpdatePositionTypes, NomenclatureType } from "../types";
import InvoiceValueInput from "../invoice/components/invoiceValueInput";
import SumInput from "./SumInput";
import "pages/registry-document/customSelectOption.scss";

const getToolTipLabel = (option: Nomenclature | NomenclatureTooltipType) => {
  return (
    <div className="select-tooltip-container">
      <p className="select-tooltip-item select-tooltip-item-label">
        {option.label} <br />
      </p>
      <p className="select-tooltip-item">
        {option?.path || ""} <br />
      </p>
      <p className="select-tooltip-item">
        {option?.category || ""} <br />
      </p>
    </div>
  );
};

type NomenclatureTooltipType = Pick<Nomenclature, "path" | "category" | "label">;

const NomenclatureTable = observer(() => {
  const { RootStore } = useStore();
  const {
    RegistryDocumentStore: {
      advanceReport,
      setAdvanceReport,
      nomenclaturesList,
      setPickedNomenclatureId,
      setPickedPositionId,
      setPickedUnitId,
      unitsList,
      selectedIdx,
      pickedIdx,
      setSelectedIdx,
      setPickedIdx,
      updatePosition,
      setPickedValue,
      setPickedSum,
      setPickedValueFact,
    },
  } = RootStore;

  const tableRef = useRef<HTMLDivElement>(null);

  const handleTableElementClick = useCallback(
    function (event: React.MouseEvent<HTMLDivElement>, index: number, id: number) {
      if (!(advanceReport.status === "pending")) return;

      if (event.detail === 1) {
        if (index === selectedIdx) {
          setPickedIdx(index);
        } else {
          setSelectedIdx(index);
          setPickedIdx(0);
        }
      }

      if (event.detail === 2) {
        if (index === pickedIdx) {
          setPickedIdx(0);
        } else {
          setPickedIdx(index);
        }
      }
      setPickedPositionId(id);
    },
    [
      advanceReport.status,
      pickedIdx,
      selectedIdx,
      setPickedIdx,
      setPickedPositionId,
      setSelectedIdx,
    ]
  );

  const onNameSelect = useCallback(
    async (val: string, option: any, value: any, index: any) => {
      const result = await updatePosition(value.id, UpdatePositionTypes.NOMENCLATURE_ID, val);
      const unit = toJS(unitsList).find((unit: any) => +result?.unit_id === unit.value);

      const updatedPositions = toJS(advanceReport.positions);
      updatedPositions[index].name = nomenclaturesList?.find(
        (obj: any) => obj.value === val
      )?.label;
      console.log(updatedPositions[index]);

      updatedPositions[index].unit = unit.label || "";
      setAdvanceReport({
        ...toJS(advanceReport),
        positions: updatedPositions,
      });
    },
    [advanceReport, nomenclaturesList, setAdvanceReport, unitsList, updatePosition]
  );

  useEffect(() => {
    setPickedNomenclatureId(null);
    setPickedSum(null);
    setPickedValue(null);
    setPickedValueFact(null);
    setPickedUnitId(null);

    function handleClickOutside(event: MouseEvent) {
      if (
        tableRef.current &&
        !tableRef.current.contains(event.target as Node) &&
        ![
          "select-option-main-label ant-tooltip-open",
          "ant-select-item ant-select-item-option ant-select-item-option-active",
          "ant-tooltip-inner",
          "ant-tooltip-open",
          "select-option-main-label",
          "select-option",
          "select-option-type-label",
        ].includes((event.target as any)?.className)
      ) {
        setSelectedIdx(0);
        setPickedIdx(0);
      }
    }

    document.addEventListener("mousedown", handleClickOutside, {
      capture: true,
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, {
        capture: true,
      });
    };
  }, []);

  const nomenclaturesListData = useMemo(
    () =>
      toJS(nomenclaturesList)?.map((option: Nomenclature) => (
        <Select.Option key={option.value} value={option.value} label={option.label}>
          <div className="select-option">
            <Tooltip title={getToolTipLabel(option)}>
              <span className="select-option-main-label">{option.label}</span>
            </Tooltip>
            <span className="select-option-type-label">{NomenclatureType[option.type]}</span>
          </div>
        </Select.Option>
      )),
    [nomenclaturesList]
  );

  const positionsData = useMemo(() => {
    if (!advanceReport.positions || advanceReport.positions.length <= 0) return <EmptyTableBody />;

    return toJS(advanceReport.positions).map((value: any, index: number) => {
      return (
        <PositionRow
          key={value.id}
          index={index}
          position={value}
          nomenclaturesListData={nomenclaturesListData}
          handleTableElementClick={handleTableElementClick}
          onNameSelect={onNameSelect}
        />
      );
    });
  }, [advanceReport.positions, handleTableElementClick, nomenclaturesListData, onNameSelect]);

  return (
    <>
      <div className="registry-document__table" ref={tableRef}>
        <div className="registry-document__table-header">
          <div className="registry-document__table-header-element f52"></div>

          <div className="registry-document__table-header-element f586">Номенклатура</div>

          <div className="registry-document__table-header-element f150">Цена, ₽</div>
          <div className="registry-document__table-header-element f150">Количество</div>
          <div className="registry-document__table-header-element f72">Ед.изм</div>
          <div className="registry-document__table-header-element f150">Сумма, ₽</div>
          {advanceReport.status === "pending" && (
            <div className="registry-document__inv-table__header__element"></div>
          )}
        </div>
        <div className="registry-document__table-body">{positionsData}</div>
      </div>
      <AddPositionButton />
    </>
  );
});

const AddPositionButtonComponent = () => {
  const {
    RootStore: {
      RegistryDocumentStore: {
        createNewPosition,
        setAdvanceReport,
        advanceReport,
        updatePositionIsLoading,
      },
    },
  } = useStore();
  const [createIsLoading, setCreateIsLoading] = useState(false);

  const handleCreatePosition = useCallback(
    async function () {
      setCreateIsLoading(true);
      const id = await createNewPosition();
      setAdvanceReport({
        ...toJS(advanceReport),
        positions: [
          ...toJS(advanceReport).positions,
          { id: id, name: null, sum: "", unit: "", value: "" },
        ],
      });
      setCreateIsLoading(false);
    },
    [advanceReport, createNewPosition, setAdvanceReport]
  );

  if (!advanceReport || advanceReport.status !== "pending") return null;

  return (
    <Button
      variant={EButtonVariant.createButton}
      text="Добавить позицию"
      maxWidth={"184px"}
      height={"32px"}
      borderRadius={"10px"}
      onClick={handleCreatePosition}
      disabled={createIsLoading || updatePositionIsLoading}
    />
  );
};
const AddPositionButton = observer(AddPositionButtonComponent);

interface PositionRowProps {
  index: number;
  nomenclaturesListData?: ReactNode[];
  position: any;
  handleTableElementClick(event: React.MouseEvent<HTMLDivElement>, index: number, id: number): void;
  onNameSelect(val: string, option: any, value: any, index: any): Promise<void>;
}

const PositionRowComponent: FC<PositionRowProps> = ({
  index,
  nomenclaturesListData,
  position,
  handleTableElementClick,
  onNameSelect,
}) => {
  const {
    RootStore: {
      RegistryDocumentStore: { selectedIdx, pickedIdx, fetchDeletePosition, advanceReport },
    },
  } = useStore();
  const [isEdit, setIsEdit] = useState(false);

  const nomenclatureIndex = index + 0.2;
  const countIndex = index + 0.4;
  const sumIndex = index + 0.6;

  const { id, name, path, category, sum, value, unit } = position;

  const price = useMemo(() => {
    if (!value || value === "0" || !sum || sum === "0") return null;
    const percent = sum / value;
    if (percent % 1 === 0) return percent;

    return percent.toFixed(2);
  }, [sum, value]);

  return (
    <div className="registry-document__table-body-row" key={index}>
      <div className={`registry-document__table-element number f52`}>{index + 1}</div>
      <div
        className={`registry-document__table-element name f586 ${
          selectedIdx === nomenclatureIndex ? "selected" : ""
        } ${pickedIdx === nomenclatureIndex ? "picked" : ""} ${name ? "picked" : "non-picked"}`}
        onClick={event => {
          handleTableElementClick(event, nomenclatureIndex, id);
        }}
      >
        {selectedIdx === nomenclatureIndex && (
          <Select
            className={`search-select ${name ? "picked" : "non-picked"}`}
            showSearch
            autoFocus
            defaultOpen
            placeholder={name !== null ? name : "Выберите номенклатуру"}
            optionFilterProp="children"
            onChange={async (val: string, option: any) =>
              await onNameSelect(val, option, position, index)
            }
            filterOption={(input, option) =>
              (typeof option?.label === "string" ? option.label.toLowerCase() : "").includes(
                input.toLowerCase()
              )
            }
          >
            {nomenclaturesListData}
          </Select>
        )}
        {selectedIdx !== nomenclatureIndex && name !== null ? (
          <Tooltip
            placement="leftTop"
            title={getToolTipLabel({
              label: name,
              path: path,
              category: category,
            })}
          >
            {name}
          </Tooltip>
        ) : (
          "Выберите номенклатуру"
        )}
      </div>
      <div className={`registry-document__table-element price f150  ${isEdit ? "disabled" : ""}`}>
        {price}
      </div>
      <div
        className={`registry-document__table-element amount f150 ${
          selectedIdx === countIndex ? "selected" : ""
        } ${pickedIdx === countIndex ? "picked" : ""} ${value ? "picked" : "non-picked"}`}
        onClick={event => {
          event.stopPropagation();
          handleTableElementClick(event, countIndex, id);
        }}
      >
        {selectedIdx === countIndex && (
          <InvoiceValueInput setIsEdit={setIsEdit} value={position} index={index} />
        )}
        {selectedIdx !== countIndex && value !== null ? value : ""}
      </div>
      <div className={`registry-document__table-element type f72`}>{unit}</div>
      <div
        className={`registry-document__table-element sum f150 ${
          selectedIdx === sumIndex ? "selected" : ""
        } ${pickedIdx === sumIndex ? "picked" : ""}  ${sum ? "picked" : "non-picked"}`}
        onClick={event => {
          event.stopPropagation();
          handleTableElementClick(event, sumIndex, id);
        }}
      >
        {selectedIdx !== sumIndex && sum !== null ? sum : ""}
        {selectedIdx === sumIndex && (
          <SumInput setIsEdit={setIsEdit} value={position} index={index} />
        )}
      </div>
      {advanceReport.status === "pending" && (
        <div
          style={{ cursor: "pointer" }}
          className="registry-document__table-element"
          onClick={() => fetchDeletePosition(position)}
        >
          <BucketIcon />
        </div>
      )}
    </div>
  );
};

const PositionRow = observer(PositionRowComponent);

export default NomenclatureTable;
