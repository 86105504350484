import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "../../table-styles.module.scss";
import { IRevenueExpenseArticle } from "types/manager-reports";
import { CellValue } from "../cell-value";

interface RowProps {
  article?: IRevenueExpenseArticle;
}

const RowComponent: FC<RowProps> = ({ article }) => {
  const isPositive = useMemo(() => {
    if (!article) return null;
    if (typeof article.positive !== "boolean") return "";
    if (article.positive) return cls["positive"];
    return cls["negative"];
  }, [article]);

  if (!article)
    return (
      <tr className={cls["tr"]}>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}></td>
        <td className={`${cls["td"]} ${isPositive}`}></td>
      </tr>
    );

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]}>{article.article}</td>
      <td className={cls["td"]}>
        <CellValue articleCellValue={article.plan} />
      </td>
      <td className={cls["td"]}>
        <CellValue articleCellValue={article.fact} />
      </td>
      <td className={`${cls["td"]} ${isPositive}`}>
        <CellValue articleCellValue={article.deviation} />
      </td>
    </tr>
  );
};

export const Row = observer(RowComponent);
