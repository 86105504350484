import { IFinResultArticle } from "types/manager-reports";

export const mockFinResults: IFinResultArticle[] = [
  // 1. Оба числовых значения с символами
  {
    name: "Выручка от продаж",
    jan: { value: 150000, symbol: "₽" },
    feb: { value: 180000, symbol: "₽" },
    dynamic_difference: { value: 30000, symbol: "₽" },
    positive: true,
  },
  {
    name: "Налоговые отчисления",
    jan: { value: 25000, symbol: "%" },
    feb: { value: 27000, symbol: "%" },
    dynamic_difference: { value: 2000, symbol: "%" },
    positive: true,
  },

  // 2. Отрицательная динамика
  {
    name: "Затраты на логистику",
    jan: { value: 45000, symbol: "₽" },
    feb: { value: 42000, symbol: "₽" },
    dynamic_difference: { value: -3000, symbol: "₽" },
    positive: false,
  },

  // 3. Смешанные символы валют
  {
    name: "Курсовые разницы",
    jan: { value: 1200, symbol: "%" },
    feb: { value: 1500, symbol: "₽" },
    dynamic_difference: { value: 300, symbol: "₽" },
    positive: true,
  },

  // 4. Значения с латинскими символами
  {
    name: "Списания (код A)",
    jan: { value: "A", symbol: null },
    feb: { value: "B", symbol: null },
    dynamic_difference: { value: "C", symbol: null },
    positive: null,
  },
  {
    name: "Резервы (код X)",
    jan: { value: "X", symbol: null },
    feb: { value: 15000, symbol: "%" },
    dynamic_difference: { value: "Y", symbol: null },
    positive: null,
  },

  // 5. Null значения
  {
    name: "Неопознанные поступления",
    jan: { value: null, symbol: null },
    feb: { value: 5000, symbol: "₽" },
    dynamic_difference: { value: null, symbol: null },
    positive: null,
  },
  {
    name: "Устаревшие активы",
    jan: { value: 75000, symbol: "%" },
    feb: { value: null, symbol: null },
    dynamic_difference: { value: null, symbol: null },
    positive: null,
  },

  // 6. Комбинированные кейсы
  {
    name: "Смешанный тип 1",
    jan: { value: 100, symbol: "%" },
    feb: { value: "D", symbol: null },
    dynamic_difference: { value: "E", symbol: null },
    positive: null,
  },
  {
    name: "Смешанный тип 2",
    jan: { value: "F", symbol: null },
    feb: { value: 200, symbol: "₽" },
    dynamic_difference: { value: "G", symbol: null },
    positive: null,
  },

  // 7. Нулевая разница
  {
    name: "Фиксированные расходы",
    jan: { value: 30000, symbol: "₽" },
    feb: { value: 30000, symbol: "₽" },
    dynamic_difference: { value: 0, symbol: "₽" },
    positive: true,
  },

  // 8. Разные форматы процентов
  {
    name: "Рентабельность",
    jan: { value: 15.5, symbol: "%" },
    feb: { value: 17.2, symbol: "%" },
    dynamic_difference: { value: 1.7, symbol: "%" },
    positive: true,
  },

  // 9. Большие числа
  {
    name: "Основные фонды",
    jan: { value: 1250000, symbol: "₽" },
    feb: { value: 1300000, symbol: "₽" },
    dynamic_difference: { value: 50000, symbol: "₽" },
    positive: true,
  },

  // 10. Граничные случаи
  {
    name: "Тест 1: Все null",
    jan: { value: null, symbol: null },
    feb: { value: null, symbol: null },
    dynamic_difference: { value: null, symbol: null },
    positive: null,
  },
  {
    name: "Тест 2: Символ и null",
    jan: { value: "Z", symbol: null },
    feb: { value: null, symbol: "₽" },
    dynamic_difference: { value: null, symbol: null },
    positive: null,
  },
  {
    name: "Тест 3: Разные символы",
    jan: { value: 100, symbol: "%" },
    feb: { value: 150, symbol: "₽" },
    dynamic_difference: { value: 50, symbol: "₽" },
    positive: true,
  },
];

// Добавляем еще 7 статей для общего количества 20
for (let i = 14; i <= 20; i++) {
  mockFinResults.push({
    name: `Доп. статья ${i}`,
    jan: { value: i * 1000, symbol: i % 2 === 0 ? "₽" : "%" },
    feb: { value: i * 1200, symbol: i % 3 === 0 ? "₽" : "%" },
    dynamic_difference: { value: i * 200, symbol: i % 4 === 0 ? "₽" : "%" },
    positive: true,
  });
}
