import { observer } from "mobx-react-lite";
import { FC, useCallback, useMemo } from "react";
import cls from "./styles.module.scss";
import dayjs from "dayjs";
import { useStore } from "store";
import { InfoMessage } from "widgets/info-message";

interface ManagerReportsHeaderProps {}

const ManagerReportsHeaderComponent: FC<ManagerReportsHeaderProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: {
        activeTab,
        setActiveTab,
        actualDate,
        finResults,
        revenueExpenses,
        dividends,
      },
    },
  } = useStore();

  const isActive = useCallback(
    (id: number) => (activeTab === id ? cls["active"] : ""),
    [activeTab]
  );

  const reportsNotFormed = useMemo(
    () => [finResults?.length, revenueExpenses?.length, dividends?.length].every(num => num === 0),
    //TODO вернуть проверку || !lastUpdateDate когда появится это поле в апи ,
    [dividends?.length, finResults?.length, revenueExpenses?.length]
  );

  //TODO Добавить внутрь dayjs параметра lastUpdateDate когда появится поле в апи
  const infoMessage = useMemo(() => {
    if (reportsNotFormed)
      return (
        <p key={"reports-info-message"}>
          Для формирования отчета необходим хотя бы один закрытый период
        </p>
      );
    return null;
  }, [reportsNotFormed]);

  return (
    <div className={cls["page-header"]}>
      {infoMessage && (
        <div style={{ alignSelf: "flex-end", marginBottom: "-16px" }}>
          <InfoMessage width="fit-content" height="fit-content" messages={[infoMessage]} />
        </div>
      )}
      <div className={cls["row"]}>
        <h1 className={cls["page-title"]}>
          Отчет управляющего
          {actualDate && (
            <>
              {` за `}
              <span>{dayjs(actualDate, "DD.MM.YYYY").format("MMMM")}</span>
            </>
          )}
        </h1>
      </div>
      <ul className={cls["tabs"]}>
        <li id="tab-1" onClick={() => setActiveTab(1)} className={`${cls["tab"]} ${isActive(1)}`}>
          Финансовый результат
        </li>
        <li id="tab-2" onClick={() => setActiveTab(2)} className={`${cls["tab"]} ${isActive(2)}`}>
          Выручка и затраты
        </li>
        <li id="tab-3" onClick={() => setActiveTab(3)} className={`${cls["tab"]} ${isActive(3)}`}>
          Финансовая деятельность
        </li>
        <li id="tab-4" onClick={() => setActiveTab(4)} className={`${cls["tab"]} ${isActive(4)}`}>
          Баланс
        </li>
      </ul>
    </div>
  );
};

export const ManagerReportsHeader = observer(ManagerReportsHeaderComponent);
