import { FC } from "react";

interface CrossIconProps {
  className?: string;
}

export const CrossIcon: FC<CrossIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.0313 1.95942C9.30467 1.68606 9.30467 1.24284 9.0313 0.969473C8.75794 0.696106 8.31472 0.696106 8.04135 0.969473L5.00079 4.01003L1.96023 0.969473C1.68687 0.696106 1.24365 0.696106 0.970285 0.969473C0.696918 1.24284 0.696918 1.68606 0.970285 1.95942L4.01084 4.99998L0.970285 8.04054C0.696918 8.31391 0.696917 8.75712 0.970284 9.03049C1.24365 9.30386 1.68687 9.30386 1.96023 9.03049L5.00079 5.98993L8.04135 9.03049C8.31472 9.30386 8.75794 9.30386 9.0313 9.03049C9.30467 8.75712 9.30467 8.31391 9.0313 8.04054L5.99074 4.99998L9.0313 1.95942Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.0313 1.95942C9.30467 1.68606 9.30467 1.24284 9.0313 0.969473C8.75794 0.696106 8.31472 0.696106 8.04135 0.969473L5.00079 4.01003L1.96023 0.969473C1.68687 0.696106 1.24365 0.696106 0.970285 0.969473C0.696918 1.24284 0.696918 1.68606 0.970285 1.95942L4.01084 4.99998L0.970285 8.04054C0.696918 8.31391 0.696917 8.75712 0.970284 9.03049C1.24365 9.30386 1.68687 9.30386 1.96023 9.03049L5.00079 5.98993L8.04135 9.03049C8.31472 9.30386 8.75794 9.30386 9.0313 9.03049C9.30467 8.75712 9.30467 8.31391 9.0313 8.04054L5.99074 4.99998L9.0313 1.95942Z"
        fill="black"
        fill-opacity="0.2"
      />
    </svg>
  );
};
