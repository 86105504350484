import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import * as yup from "yup";

const MAX_SUM = 999999999.99;

export const schema = yup.object().shape({
  user_contragent_id: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  sum: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required))
    .when("number", (d, schema) => schema.notRequired()),
  date: yup
    .string()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  created_date: yup
    .string()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  positions: yup
    .array()
    .of(
      yup.object({
        nomenclature_id: yup
          .string()
          .typeError("Выберите номенклатуру из списка")
          .required("Выберите номенклатуру из списка"),
        value: yup
          .number()
          .typeError(ValidationMessages.get(ValidationTypes.required) as string)
          .min(0.001, `Количество должно быть больше 0`)
          .max(999999.999, ({ max }) => `Количество должно быть не более ${max}`)
          .required(ValidationMessages.get(ValidationTypes.required))
          .nullable(),
        unit_id: yup
          .number()
          .typeError(ValidationMessages.get(ValidationTypes.required) as string)
          .required(ValidationMessages.get(ValidationTypes.required)),
        sum: yup
          .number()
          .typeError(ValidationMessages.get(ValidationTypes.required) as string)
          .min(0.01, ({ min }) => `Сумма должна быть больше 0`)
          .max(MAX_SUM, ({ max }) => `Сумма не должна быть более ${max}`)
          .required(ValidationMessages.get(ValidationTypes.required)),
      })
    )
    .required(),
});
