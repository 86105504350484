import React from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router";
import { InvoicePage } from "./invoice/invoice";
import { CashPage } from "./cash/cash";
import { AdvancePage } from "./advance/advance";
import { InventoryPage } from "./inventory/inventory";
import { ActServices } from "./services/act/act";
import { AdvanceServices } from "./services/advance/advance";
import { InvoiceCreate } from "./invoice/new/invoice";
import { AdvanceCreate } from "./advance/new/advance";

export const RegistryDocument = observer(() => {
  return (
    <>
      <Routes>
        <Route path="invoice">
          <Route path="create" element={<InvoiceCreate />} />
          <Route path="*" index element={<InvoicePage />} />
        </Route>
        <Route path="invoice_out" element={<InvoicePage />} />
        <Route path="cash" element={<CashPage />} />
        <Route path="advance">
          <Route path="create" element={<AdvanceCreate />} />
          <Route path="*" index element={<AdvancePage />} />
        </Route>
        <Route path="inventory" element={<InventoryPage />} />
        <Route path="act_service/*" element={<ActServices />} />
        <Route path="advance_report_service/*" element={<AdvanceServices />} />
      </Routes>
    </>
  );
});
