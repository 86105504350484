import { api, IBaseResponse } from "./api";
import type {
  Contragent,
  CreateLoanBody,
  DefaultRequestBody,
  GetContragentListResponse,
  GetLoanListBody,
  GetLoanListResponse,
  IGetLoansResponse,
  Loan,
  LoanPosition,
  LoanRefundBody,
} from "../types/loan";

// _mocks
import { mockData } from "./mock";
import { getLoansListData } from "../_mocks/loan/getLoansList";
import { getContragentsData } from "../_mocks/loan/getContragents";
import { getLoanRecordData } from "../_mocks/loan/getLoanRecord";
import { getOpenPosition } from "../_mocks/loan/getOpenPosiiton";
import { refundLoanData } from "../_mocks/loan/refundLoan";

const IS_MOCK = true;

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;
export const BASE_API_CONFIG = { baseURL: BASE_API_URL_V3 };

export async function getLoansList(body?: GetLoanListBody): Promise<GetLoanListResponse | null> {
  try {
    const response = await api.post<IBaseResponse<IGetLoansResponse>>(
      "/loans/spots/list",
      body,
      BASE_API_CONFIG
    );
    return response.data.data;
  } catch {
    return null;
  }
}

export async function createLoan(body: CreateLoanBody) {
  try {
    const response = await api.post<IBaseResponse<Loan>>(
      "/loans/spots/create",
      body,
      BASE_API_CONFIG
    );
    if (!response.data.success) throw new Error();
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function closeLoan(body: DefaultRequestBody) {
  try {
    const response = await api.post<IBaseResponse<Loan>>(
      "/loans/spots/close",
      body,
      BASE_API_CONFIG
    );
    return response.data.data;
  } catch {
    return null;
  }
}

export async function getContragents(): Promise<Contragent[]> {
  try {
    const response = await api.get<{ data: Contragent[] }>(
      "/loans/spots/getContragents",
      BASE_API_CONFIG
    );
    return response.data.data;
  } catch {
    return [];
  }
}

export async function getLoanRecord(body: DefaultRequestBody): Promise<Loan | null> {
  try {
    const response = await api.post("/loans/spots/get", body, BASE_API_CONFIG);
    return response.data.data;
  } catch {
    return null;
  }
}

export async function getOpenPositions(body: DefaultRequestBody): Promise<LoanPosition[] | null> {
  try {
    if (IS_MOCK) return mockData(getOpenPosition);
    return api.post("/loans/spots/getOpenPositions", body, BASE_API_CONFIG);
  } catch {
    return null;
  }
}

export async function refundLoan(body: LoanRefundBody): Promise<Loan | unknown> {
  try {
    const response = await api.post("/loans/spots/refund", body, BASE_API_CONFIG);
    return response.data;
  } catch (e) {
    return e;
  }
}
