import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { LoadFileInput } from "ui-new/file-loader";
import cls from "../styles.module.scss";
import { uploadFile } from "api/registryDocumentNew";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CrossIcon } from "ui-new/icons/cross-icon";
import imageCompression from "browser-image-compression";

interface FilesProps {}

const FilesComponent: FC<FilesProps> = () => {
  const { control } = useFormContext<{ files?: { id: number }[] }>();
  const { append, remove, fields } = useFieldArray({ control, name: "files", keyName: "_id" });
  const [files, setFiles] = useState<{ file_id: number; file_url: string }[]>([]);

  const onChangeHandler = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          fileType: "image/webp",
          initialQuality: 0.8,
        };

        const formData = new FormData();
        if (file.size < 1024 * 1024) {
          formData.append("file", file);
        } else {
          const compressedFile = await imageCompression(file, options);
          formData.append("file", compressedFile);
        }

        uploadFile(formData).then(res => {
          if (!res) return;
          append({ id: res.file_id });
          setFiles(prev => [...prev, res]);
        });
      } catch (error) {
        console.log(error, "Ошибка сжатия");
      }
    },
    [append]
  );

  return (
    <div className={cls["files-container"]}>
      <LoadFileInput
        id={"file"}
        inputProps={{
          disabled: fields.length >= 10,
          accept: "image/*",
          onChange: onChangeHandler,
        }}
      />
      {fields.map((f, i) => {
        const file = files.find(file => file.file_id === f.id);
        if (!file) return null;
        return <Image key={f._id} remove={() => remove(i)} src={file.file_url} />;
      })}
    </div>
  );
};

interface ImageProps {
  src: string;
  remove: () => void;
}

const Image: FC<ImageProps> = ({ src, remove }) => {
  return (
    <div className={cls["file-container"]}>
      <button onClick={remove} className={cls["remove-button"]}>
        <CrossIcon />
      </button>
      <img src={src} alt="Изображение" />
    </div>
  );
};

export const Files = observer(FilesComponent);
