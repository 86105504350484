import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import cls from "../../table-styles.module.scss";
import { IFinResultArticle } from "types/manager-reports";
import { CellValue } from "../cell-value";
import { monthsMap } from "utils/functions/mapMonths";

interface RowProps {
  article?: IFinResultArticle;
  index: number;
}

const RowComponent: FC<RowProps> = ({ article, index }) => {
  const isPositive = useMemo(() => {
    if (!article) return null;
    if (typeof article.positive !== "boolean") return "";
    if (article.positive) return cls["positive"];
    return cls["negative"];
  }, [article]);

  const monthsData = useMemo(() => {
    if (!article)
      return (
        <>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
        </>
      );
    return monthsMap(month => {
      const cellVal = article[month];
      if (!cellVal) return null;
      return (
        <td key={article.name + "-" + month} className={cls["td"]}>
          <CellValue articleCellValue={cellVal} />
        </td>
      );
    });
  }, [article]);

  if (!article)
    return (
      <tr className={cls["tr"]}>
        <td className={cls["td"]} style={{ textAlign: "center" }}>
          {index + 1}
        </td>
        <td className={cls["td"]}></td>
        {monthsData}
        <td className={`${cls["td"]} ${isPositive}`}></td>
      </tr>
    );

  return (
    <tr className={cls["tr"]}>
      <td className={cls["td"]} style={{ textAlign: "center" }}>
        {index + 1}
      </td>
      <td className={cls["td"]}>{article.name}</td>
      {monthsData}
      <td className={`${cls["td"]} ${isPositive}`}>
        <CellValue articleCellValue={article.dynamic_difference} />
      </td>
    </tr>
  );
};

export const Row = observer(RowComponent);
