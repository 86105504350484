import React, { FC, useEffect, useMemo, useRef } from "react";
import type {
  ChangeItem,
  OddsTableItem,
  TableItemCreate,
  TableItemResult,
} from "../../../../types/odds";
import TableRow from "../table-row";
import { dayjs } from "../../../../utils/plugins/dayjs";
import { Loader } from "../../../../ui-new/loader";
import type { OddsDay } from "../../types";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface PnlReportTableProps {
  currentDate: any;
  updateServerState: (items: ChangeItem[]) => void;
  updateTableItems: (data: OddsTableItem, day: OddsDay, editedItem: TableItemCreate) => void;
  tableData: OddsTableItem[];
  isTableLoading: boolean;
}

export const OddsTable: FC<PnlReportTableProps> = ({
  updateServerState,
  updateTableItems,
  tableData,
  isTableLoading,
  currentDate,
}) => {
  const tableWrapper = useRef<HTMLTableElement>(null);

  const days = useMemo(() => {
    const currentDayjs = dayjs(currentDate);
    const currentMonth = currentDayjs.month();
    const currentYear = currentDayjs.year();
    const daysArray = [];
    const daysInMonth = dayjs(`${currentYear}-${currentMonth + 1}`).daysInMonth();

    for (let day = 1; day <= daysInMonth; day++) {
      daysArray.push({
        date: dayjs(`${currentYear}-${currentMonth + 1}-${day}`).format("DD.MM.YY"),
      });
    }

    return daysArray;
  }, [currentDate]);

  useEffect(() => {
    if (!tableWrapper.current || !isTableLoading) return;
    const wrapper = tableWrapper.current;
    const resizeWrapper = () => {
      wrapper.style.height = `${document.documentElement.clientHeight - wrapper.offsetTop}px`;
    };
    resizeWrapper();
    window.addEventListener("resize", resizeWrapper);
    return () => window.removeEventListener("resize", resizeWrapper);
  }, [isTableLoading]);

  if (isTableLoading) {
    return <Loader />;
  }

  return (
    <div
      ref={tableWrapper}
      style={{
        overflow: "auto",
        paddingRight: "20px",
        marginRight: "-20px",
      }}
    >
      <table className={styles.reportTable}>
        <colgroup>
          <col width={300} />
          {days.map(() => (
            <>
              <col width={280} />
            </>
          ))}
        </colgroup>
        <thead className={styles.tableHeader}>
          <tr>
            <th className={classNames(styles.title, styles.titleFirst)}>Разделы</th>
            {days.map(day => (
              <th className={classNames(styles.monthCell, styles.title)}>{day.date}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <TableRow
              key={row.code}
              data={row}
              isCategory={!row.items.length}
              updateTableItems={updateTableItems}
              updateServerState={updateServerState}
              days={days}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
