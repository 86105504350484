import { FC } from "react";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
export const MIN_DATE = "2024-12-01";

interface DatesProps {
  allDisabled: boolean;
}

const DatesComponent: FC<DatesProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ date: Date; created_date: Date }>();

  return (
    <>
      <Input
        label="Дата создания"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="date"
        name="created_date"
        inputProps={{
          min: MIN_DATE,
          disabled: true,
        }}
      />
      <Input
        label="Дата проведения"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="date"
        name="date"
        inputProps={{
          min: MIN_DATE,
          disabled: allDisabled,
        }}
      />
    </>
  );
};

export const DateFields = observer(DatesComponent);
