export interface Contragent {
  title: string;
  surname: string;
  name: string;
  user_contragent_id: number;
  contragent_id: number;
  id: number;
}

export interface Article {
  id: number;
  title: string;
  need_contragent: boolean;
}

export interface Deal {
  id: number;
  title: string;
  number: string;
}

export type NomenclatureTypeKey =
  | "DISH"
  | "GOODS"
  | "MODIFIER"
  | "SERVICE"
  | "OUTER"
  | "PREPARED"
  | "RATE";

export type NomenclatureGoods = Extract<NomenclatureTypeKey, "GOODS">;

export interface UpdateStatusParams {
  document_id: number;
  status: string;
  document_type: string;
  comment: string;
}

export const NomenclatureType: Record<NomenclatureTypeKey, string> = {
  DISH: "Блюдо",
  GOODS: "Товары",
  MODIFIER: "Модификаторы",
  SERVICE: "Сервис",
  OUTER: "Алкоголь",
  PREPARED: "Заготовки",
  RATE: "Акции",
};

export interface Nomenclature {
  label: string;
  value: string;
  type: NomenclatureTypeKey;
  unit: string;
  category?: string;
  path?: string;
}

export interface IInventoryPositon {
  id: number;
  value: number;
  value_fact: number;
  cost: number;
  sum: number;
  sum_fact: number;
  unit: string;
  name: string;
}

export interface IReceiver {
  id: number;
  name: string;
  bid_id: number;
  bid_status: string;
}

export interface IInventoryReport {
  id: number;
  number: number;
  date: string;
  created_at: string;
  responsible_id: number;
  responsible: string;
  sum: number;
  status_ru: string;
  status: string;
  comment: string | null;
  receivers: IReceiver[];
  positions: IInventoryPositon[];
  bids: any[];
}

export interface IResponsibleStatus {
  id: number;
  responsible: string;
  responsible_id: number;
  status: string;
}

export enum UpdatePositionTypes {
  NOMENCLATURE_ID = "nomenclature_id",
  VALUE = "value",
  UNIT_ID = "unit_id",
  SUM = "sum",
  VALUE_FACT = "value_fact",
}

export interface DocumentData {
  id: number;
  number: string;
  guid_1c: string | null;
  date: string;
  created_at: string;
  status: string; // TODO: Add enum
  coment: string;
  deal_id: number;
  deal_num: string;
  contragent_id: string;
  contragent: string;
  responsible_id: number;
  responsible: string;
}

export interface DistributeDocumentParams {
  id: number;
}

export type DistributionDocumentType = "advance" | "invoice";
