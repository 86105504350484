import { FC, useCallback, useEffect, useMemo, useState } from "react";
import cls from "../styles.module.scss";
import { AdvanceServiceHeader } from "./header";
import { useLocation, useNavigate } from "react-router";
import { Form } from "./form";
import { FormProvider, useForm } from "react-hook-form";
import { useStore } from "store";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { advanceReportSchema } from "./validation";
import { observer } from "mobx-react-lite";
import { BigSkeleton } from "ui";
import { IAdvanceReportServiceNewPosition } from "types/registry-document";
import { showNotificationModal } from "ui-new/alert";

export interface FormValues {
  //для создания/редактирования авансового отчета
  number: string;
  user_id: number;
  date: Date;
  contragent_id?: number | null;
  comment?: string | null;

  //для создания/изменения позиций (не считая ком. платежей)
  pnl_article_id: number;
  sum: number | null;

  positions?: IAdvanceReportServiceNewPosition[] | null;
}

interface AdvanceServicesProps {}

const AdvanceServicesComponent: FC<AdvanceServicesProps> = () => {
  const {
    RootStore: {
      HeaderStore: { profileData },
      RegistryServicesDocumentStore: {
        advanceReport,
        setAdvanceReport,
        setDocType,
        advanceReportIsLoading,
        fetchGetContragents,
        fetchGetPnLArticles,
        fetchGetAdvanceReport,
        fetchCreateAdvanceReportWithPositions,
        fetchUpdateAdvanceReportWithPositions,
        approveStatusConfirm,
        fetchGetEmployeesAndHelpers,
        setAdvanceReportIsLoading,
        period,
        utilityArticle,
      },
    },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    setDocType("advance_report_service");
    const id = location.hash.slice(1);
    if (id.length === 0) {
      setIsCreate(true);
      return;
    }
    setIsCreate(false);
    if (isNaN(Number(id))) {
      return;
    }
    fetchGetAdvanceReport(Number(id));

    return () => {
      setDocType(null);
      setAdvanceReport(null);
      setIsCreate(false);
    };
  }, [location.hash]);

  useEffect(() => {
    fetchGetContragents();
    fetchGetPnLArticles();
    fetchGetEmployeesAndHelpers();
  }, [fetchGetContragents, fetchGetPnLArticles]);

  useEffect(() => {
    if (!advanceReport) {
      reset();
      return;
    }
    const defValues: FormValues = {
      contragent_id: advanceReport.contragent_id,
      date: advanceReport.date as unknown as Date,
      number: advanceReport.number,
      pnl_article_id: advanceReport.pnl_article_id as number,
      sum: null,
      user_id: advanceReport.responsible_id,
      comment: advanceReport.comment,
    };
    if (utilityArticle && advanceReport.pnl_article_id === utilityArticle.id) {
      defValues.pnl_article_id = advanceReport.pnl_article_id as number;
      defValues.positions = advanceReport.positions as any[];
    } else {
      defValues.pnl_article_id = advanceReport.positions[0]?.pnl_article_id;
      defValues.sum = advanceReport.positions[0]?.sum;
    }
    reset(defValues);
  }, [advanceReport, utilityArticle]);

  const onCreate = useCallback(
    ({
      contragent_id,
      date,
      number,
      pnl_article_id,
      positions,
      sum,
      comment,
      user_id,
    }: FormValues) => {
      const position =
        (positions as IAdvanceReportServiceNewPosition[]) ??
        ({
          advance_report_service_id: -1,
          pnl_article_id,
          sum: sum ?? 0,
        } as IAdvanceReportServiceNewPosition);

      fetchCreateAdvanceReportWithPositions(
        { contragent_id, date: dayjs(date).format("YYYY-MM-DD"), number, comment, user_id },
        position
      ).then(advance => navigate(`.#${advance?.id}`));
    },
    [fetchCreateAdvanceReportWithPositions, navigate]
  );

  const preparedData = useCallback(
    ({
      positions,
      contragent_id,
      date,
      number,
      pnl_article_id,
      user_id,
      comment,
      sum,
    }: FormValues) => {
      if (!advanceReport) return;
      if (utilityArticle && pnl_article_id !== utilityArticle.id) {
        return {
          advanceReport: {
            contragent_id,
            date: dayjs(date).format("YYYY-MM-DD"),
            number,
            id: advanceReport.id,
            comment: comment,
            user_id: user_id,
          },
          positions: {
            advance_report_service_id: advanceReport.id,
            pnl_article_id,
            sum: sum ?? 0,
          } as IAdvanceReportServiceNewPosition,
        };
      }

      if (positions)
        return {
          advanceReport: {
            contragent_id,
            comment,
            user_id,
            date: dayjs(date).format("YYYY-MM-DD"),
            number,
            id: advanceReport.id,
          },
          positions,
        };
    },
    [advanceReport, utilityArticle]
  );

  const onUpdate = useCallback(
    async (values: FormValues) => {
      if (!advanceReport) return;
      const data = preparedData(values);
      if (!data) return;
      fetchUpdateAdvanceReportWithPositions(data.advanceReport, data.positions).then(() => {
        showNotificationModal({ title: "Авансовый отчет успешно изменён", type: "success" });
        setAdvanceReportIsLoading(false);
        return;
      });
    },
    [advanceReport, fetchUpdateAdvanceReportWithPositions, preparedData, setAdvanceReportIsLoading]
  );

  const onSave = useMemo(() => {
    if (isCreate) return onCreate;
    return onUpdate;
  }, [isCreate, onCreate, onUpdate]);

  const onApprove = useCallback(
    (values: FormValues) => {
      if (!advanceReport) return;
      const data = preparedData(values);
      if (!data) return;
      approveStatusConfirm(
        "advance_report_service",
        () => navigate(".."),
        () => fetchUpdateAdvanceReportWithPositions(data.advanceReport, data.positions)
      );
    },
    [
      advanceReport,
      approveStatusConfirm,
      fetchUpdateAdvanceReportWithPositions,
      navigate,
      preparedData,
    ]
  );

  const methods = useForm<FormValues>({
    defaultValues: { sum: null },
    resolver: yupResolver(advanceReportSchema),
  });
  const { handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    if (!profileData || !isCreate) return;
    setValue("user_id", profileData.id);
  }, [isCreate, profileData, setValue]);

  if (advanceReportIsLoading) {
    return (
      <div className={cls["wrapper"]}>
        <AdvanceServiceHeader isCreate={isCreate} onSave={() => {}} onApprove={() => {}} />
        <BigSkeleton />
      </div>
    );
  }

  return (
    <div className={cls["wrapper"]}>
      <AdvanceServiceHeader
        isCreate={isCreate}
        onSave={handleSubmit(onSave, err => console.log(err))}
        onApprove={handleSubmit(onApprove)}
      />
      <FormProvider {...methods}>
        <Form />
      </FormProvider>
    </div>
  );
};

export const AdvanceServices = observer(AdvanceServicesComponent);
