export enum DocType {
  invoices = "invoices",
  advances = "advances",
  act_service = "act_service",
  advance_report_service = "advance_report_service",
}

export interface IInvoiceNew {
  number: string;
  //YYYY-MM-DD
  date: string;
  deal_id: number;
  contragent_id: number;
  positions: IPositionNew[];
  files: number[];
}

export interface IInvoice
  extends Pick<IInvoiceNew, "contragent_id" | "date" | "deal_id" | "files" | "number"> {
  id: number;
  guid_1c: string | null;
  created_at: string;
  status: DocumentStatus;
  comment: string | null;
  deal_num: string;
  contragent: string;
  responsible_id: number;
  responsible: string;
  positions: IPosition[];
}

export interface IAdvanceNew {
  user_contragent_id: number;
  //YYYY-MM-DD
  date: string;
  files: number[];
  positions: IPositionNew[];
}

export interface IAdvance extends Pick<IAdvanceNew, "date" | "files"> {
  id: number;
  number: string;
  guid_1c: string | null;
  created_at: string;
  status: DocumentStatus;
  comment: string | null;
  contragent_id: number;
  contragent: string;
  responsible_id: number;
  responsible: string;
  positions: IPosition[];
}

export interface IPositionNew {
  nomenclature_id: string;
  value: number | null;
  unit_id: number;
  sum: number;
}

export interface IPosition
  extends Pick<
    INomenclature,
    "unit" | "name" | "type" | "is_floating_point" | "category" | "path"
  > {
  id: number;
  value: number;
  sum: number;
}

export interface IUnit {
  id: number;
  title: string;
}

export interface INomenclature {
  category: string | null;
  id: string;
  is_floating_point: boolean;
  name: string;
  path: string;
  type: NomenclatureType;
  unit: IUnit["title"];
}

export enum DocumentStatus {
  approved = "approved",
  pending = "pending",
  deleted = "deleted",
  delivery = "delivery",
  waiting = "waiting",
}

export enum NomenclatureType {
  PREPARED = "PREPARED",
  DISH = "DISH",
  GOODS = "GOODS",
  MODIFIER = "MODIFIER",
  SERVICE = "SERVICE",
  OUTER = "OUTER",
  RATE = "RATE",
}
