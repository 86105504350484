import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import cls from "./styles.module.scss";
import { useStore } from "store";
import { ManagerReportsHeader } from "./header";
import { FinResultTable } from "./tabs/fin-result";
import { RevenueExpensesTab } from "./tabs/revenue-expenses";
import { DividendsTable } from "./tabs/dividends";
import { BigSkeleton } from "ui";
import { BalanceTab } from "./tabs/balance";

interface ManagerReportsProps {}

const ManagerReportsComponent: FC<ManagerReportsProps> = () => {
  const {
    RootStore: {
      ManagerReportsStore: { activeTab, fetchGetReports, reportsIsLoading, fetchGetBalance },
    },
  } = useStore();

  useEffect(() => {
    fetchGetReports();
    fetchGetBalance();
  }, [fetchGetBalance, fetchGetReports]);

  const view = useMemo(() => {
    if (reportsIsLoading) return <BigSkeleton />;
    switch (activeTab) {
      case 1:
        return <FinResultTable />;
      case 2:
        return <RevenueExpensesTab />;
      case 3:
        return <DividendsTable />;
      case 4:
        return <BalanceTab />;
      default:
        return null;
    }
  }, [activeTab, reportsIsLoading]);

  return (
    <div className={cls["page-wrapper"]}>
      <ManagerReportsHeader />
      <div style={{ marginTop: "24px" }}>{view}</div>
    </div>
  );
};

export const ManagerReports = observer(ManagerReportsComponent);
