import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";

//Функция onInput, которая ограничивает ввод до 2 знаков после точки
const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;

  if (value.includes(".")) {
    const [integer, decimal] = value.split(".");
    if (decimal.length > 1) {
      e.target.value = `${integer}.${decimal.substring(0, 2)}`;
    }
  }
};
interface SumFieldProps {
  allDisabled: boolean;
}

const SumFieldComponent: FC<SumFieldProps> = ({ allDisabled }) => {
  const { control, watch, setValue } = useFormContext<{
    sum: number;
    positions: { sum: number }[];
  }>();

  const positions = watch("positions");

  const sum = positions.reduce((acc, position) => acc + +position.sum, 0);
  setValue("sum", sum);

  return (
    <Input
      label="Стоимость, руб"
      control={control}
      variant={EInputStyleVariant.basicInput}
      placeholder="Стоимость"
      type="number"
      name="sum"
      disabled={allDisabled}
      inputProps={{ onInput, disabled: true }}
    />
  );
};

export const SumField = SumFieldComponent;
