import "./styles.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BigSkeleton, Button, EButtonVariant } from "ui";
import { observer } from "mobx-react-lite";
import { EmptyTableBody } from "../../ui/empty-table-body";
import { TableFooter } from "widgets/table-footer";
import { UploaderModalBS } from "./components/uploaderModal";
import { useStore } from "store";
import { BankStatementsFilters } from "./components/filters/filters";
import { TableRowBS } from "./components/row";
import { TableHeader } from "./components/tableHeader";
import { IMoneyTransaction } from "types/money-transactions";
import { toJS } from "mobx";
import { UploadResultsModal } from "./components/upload-results-modal";
import { useNavigate } from "react-router";

export const BankStatementsPageComponent = () => {
  const { RootStore } = useStore();
  const { MoneyTransactionsStore } = RootStore;
  const {
    isMoneyTransactionsLoading,
    fetchGetMoneyTransactions,
    parserInfo,
    moneyTransactions,
    filterContragents,
    filterOperationTypes,
    filterPayTypes,
    filterPeriodEnd,
    filterPeriodStart,
    filterMoneyTransactionTypes,
    filterStatus,
    searchText,
    sortOrder,
    sortParam,
    startParserInfo,
    fetchCheckSBPCount: fetchCheckApproveSBP,
    confirmApproveSBP,
    sbpApproveIsLoading,
    SBPCount,
  } = MoneyTransactionsStore;

  const navigate = useNavigate();

  const currentParserInfo = useMemo(
    () => parserInfo ?? startParserInfo,
    [parserInfo, startParserInfo]
  );

  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
  const [uploadResultsModalIsOpen, setUploadResultsModalIsOpen] = useState(false);

  useEffect(() => {
    fetchCheckApproveSBP();
  }, []);

  useEffect(() => {
    fetchGetMoneyTransactions();
  }, [
    fetchGetMoneyTransactions,
    filterContragents,
    filterOperationTypes,
    filterPayTypes,
    filterPeriodEnd,
    filterPeriodStart,
    filterStatus,
    filterMoneyTransactionTypes,
    searchText,
    sortOrder,
    sortParam,
  ]);

  useEffect(() => {
    if (currentParserInfo.from === null || currentParserInfo.to === null)
      fetchGetMoneyTransactions({ page: 1, per_page: currentParserInfo.per_page });
  }, [
    currentParserInfo.from,
    currentParserInfo.per_page,
    currentParserInfo.to,
    fetchGetMoneyTransactions,
  ]);

  const onPageChange = useCallback(
    (currentPage: number) => {
      fetchGetMoneyTransactions({ per_page: currentParserInfo.per_page, page: currentPage });
    },
    [currentParserInfo.per_page, fetchGetMoneyTransactions]
  );

  const onMaxChange = useCallback(
    (maxSize: number) => {
      fetchGetMoneyTransactions({ per_page: maxSize, page: currentParserInfo.current_page });
    },
    [currentParserInfo.current_page, fetchGetMoneyTransactions]
  );

  const transactionsData = useMemo(() => {
    if (!(moneyTransactions.length > 0)) {
      return <EmptyTableBody />;
    }
    return toJS(moneyTransactions).map((statement: IMoneyTransaction) => (
      <TableRowBS rowData={statement} index={statement.id} />
    ));
  }, [moneyTransactions]);

  return (
    <div className="statements__wrapper">
      <div className="statements__header">
        <h2 className="statements__header__title">Денежные операции</h2>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", paddingRight: "15px" }}>
          {SBPCount > 0 && (
            <Button
              variant={EButtonVariant.fillLightButton}
              text={"Утвердить операции по СБП"}
              maxWidth={"165px"}
              onClick={confirmApproveSBP}
              disabled={sbpApproveIsLoading}
            />
          )}
          <Button
            variant={EButtonVariant.fillButton}
            text={"Создать операцию"}
            maxWidth={"165px"}
            onClick={() => navigate("./create")}
          />
          <Button
            variant={EButtonVariant.fillButton}
            text={"Загрузить выписку"}
            maxWidth={"165px"}
            onClick={() => setUploadModalIsOpen(true)}
          />
        </div>
      </div>
      <BankStatementsFilters />
      {isMoneyTransactionsLoading ? (
        <BigSkeleton />
      ) : (
        <div className="statements-table__wrapper">
          <TableHeader />
          <div className="statements-table__body">{transactionsData}</div>
          {moneyTransactions.length > 0 && (
            <TableFooter
              currentPage={currentParserInfo.current_page}
              setCurrentPage={onPageChange}
              maxOperationsNumber={currentParserInfo.per_page}
              setMaxOperationsNumber={onMaxChange}
              firstItem={currentParserInfo.from}
              lastItem={currentParserInfo.to}
              setFirstItem={() => {}}
              setLastItem={() => {}}
              itemsList={moneyTransactions}
              total={currentParserInfo.total}
              fromResponse
            />
          )}
        </div>
      )}
      {uploadModalIsOpen && (
        <UploaderModalBS
          onClose={() => setUploadModalIsOpen(false)}
          onLoad={() => setUploadResultsModalIsOpen(true)}
        />
      )}
      {uploadResultsModalIsOpen && (
        <UploadResultsModal onClose={() => setUploadResultsModalIsOpen(false)} />
      )}
    </div>
  );
};

export const BankStatementsPage = observer(BankStatementsPageComponent);
