import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useStore } from "store";
interface DateAndNumberProps {
  allDisabled: boolean;
}

const ResponsibleComponent: FC<DateAndNumberProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      HeaderStore: { profileData },
    },
  } = useStore();
  const { control } = useFormContext<{ responsible: string; responsible_id: number }>();

  return (
    <Input
      variant={EInputStyleVariant.basicInput}
      type="string"
      label="Ответственный"
      name="responsible_id"
      inputProps={{
        value: profileData ? `${profileData.surname} ${profileData.name}` : undefined,
      }}
      disabled={true}
      placeholder="Ответственный"
      control={control}
    />
  );
};

export const ResponsibleField = observer(ResponsibleComponent);
