import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import cls from "./styles.module.scss";
import { BigSkeleton } from "ui";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TransactionHeader } from "./components/header";
import { Form } from "./forms/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchema } from "./forms/validation";
import dayjs from "dayjs";
import { IMoneyTransaction, MoneyTransactionDynamicFormFields } from "types/money-transactions";
import { updateDisabledProperty } from "./functions";
import { toJS } from "mobx";
import { fieldRules } from "./forms/fields-dependency-rules";

const CONTRAGENT_FIELDS = [
  "contragent_id",
  "contragent_inn",
  "contragent_title",
  "deal_id",
  "deal_title",
];

export const TransactionContext = createContext<{ isCash: boolean }>({ isCash: false });

export const BankStatementEditPage = observer(() => {
  const {
    RootStore: { EditMoneyTransactionStore },
  } = useStore();
  const {
    types,
    employees,
    businessHolders,
    transaction,
    transactionIsLoading,
    fetchGetTransaction,
    setTransaction,
    payTypes,
    operationTypes,
    cashFlowArticles,
    pnlArticles,
    contragents,
    commitments,
    taxes,
    fetchGetTaxes,
    fetchGetEmployees,
    fetchGetBusinessHolders,
    fetchGetContragents,
    fetchGetPnLArticles,
    fetchGetCommitments,
    fetchGetTransactionTypes,
    fetchGetTransactionPayTypes,
    fetchGetTransactionCashFlowArticles,
    fetchGetTransactionOperationTypes,
    formFields,
    setFormFields,
    fetchCreateTransaction,
    updateTransactionConfirm,
    approveTransactionConfirm,
  } = EditMoneyTransactionStore;

  const [prevValues, setPrevValues] = useState<any[]>([]);

  const defaultValues: Partial<IMoneyTransaction> = useMemo(() => {
    if (!transaction) return { type_id: 1, [MoneyTransactionDynamicFormFields.TaxRate]: 0 };

    const contragent_id = transaction.contragent_title !== null ? transaction.contragent_id : null;
    const taxRate = transaction[MoneyTransactionDynamicFormFields.TaxRate] ?? 0;
    return { ...transaction, contragent_id, [MoneyTransactionDynamicFormFields.TaxRate]: taxRate };
  }, [transaction]);

  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(createSchema(formFields, !!transaction)),
    reValidateMode: "onChange",
  });

  const { control, reset, handleSubmit, watch, getValues, setValue } = methods;
  const selectedOperationId = watch("operation_type_id");

  const watchedFields = fieldRules.map(r => r.sourceField);
  const trackedValues = useWatch({ control, name: watchedFields, exact: true });

  useEffect(() => {
    if (!payTypes.length) fetchGetTransactionPayTypes();
    if (!operationTypes.length) fetchGetTransactionOperationTypes();
    if (!cashFlowArticles.length) fetchGetTransactionCashFlowArticles();
    if (!contragents.length) fetchGetContragents();
    if (!types.length) fetchGetTransactionTypes();
    if (!employees.length) fetchGetEmployees();
    if (!businessHolders.length) fetchGetBusinessHolders();
    if (!pnlArticles.length) fetchGetPnLArticles();
    if (!commitments.length) fetchGetCommitments();
    if (!taxes.length) fetchGetTaxes();
  }, []);

  useEffect(() => {
    const currentValues = trackedValues;
    const previousValues = prevValues;
    fieldRules.forEach((rule, index) => {
      const currentSourceValue = currentValues[index];
      const prevSourceValue = previousValues[index];
      if ((rule.condition?.(currentSourceValue, prevSourceValue) ?? true) && rule.getValue) {
        const newValue = rule.getValue(
          currentSourceValue,
          prevSourceValue,
          getValues(),
          EditMoneyTransactionStore
        );
        setValue(rule.targetField, newValue, { shouldValidate: true, shouldDirty: true });
      }
    });
    setPrevValues(currentValues);
  }, [trackedValues, EditMoneyTransactionStore, getValues, setValue]);

  useEffect(() => {
    const id = Number(location.hash.slice(1));
    if (isNaN(id)) return;
    if (location.hash.length === 0) return;

    fetchGetTransaction(id);

    return () => setTransaction(null);
  }, [location.hash]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    const operationType = operationTypes.find(op => op.id === selectedOperationId);
    if (!transaction) {
      setFormFields(updateDisabledProperty(toJS(operationType)?.fields ?? {}) ?? null);
    } else {
      setFormFields(operationType?.fields ?? null);
    }
  }, [operationTypes, selectedOperationId]);

  useEffect(() => {
    if (!formFields) return;
    const valuesFromDefFields: { [key: string]: any } = {};
    Object.entries(formFields).forEach(
      ([key, props]) => (valuesFromDefFields[key] = props.default)
    );
    reset(formFields, { keepValues: true, keepDefaultValues: true });
  }, [formFields]);

  const onSubmit = useCallback(
    (values: any) => {
      const contragentType =
        formFields?.contragent_type?.default === 1
          ? "user"
          : formFields?.contragent_type?.default === 2
          ? "business_holder"
          : "contragent";

      const {
        id,
        amount,
        date,
        date_delivery,
        number,
        pay_type_id,
        pay_type_title,
        description,
        operation_type_id,
        operation_type_title,
        spot_bank_account_number,
        spot_bank_account_id,
        spot_id,
        spot_title,
        status_id,
        status_title,
        type_id,
        type_title,
        ...otherValues
      } = values;

      const currentValues: { [key: string]: any } = {};
      Object.entries(otherValues).forEach(([key, value]) => {
        let checkKey = key;
        if (key === "items") checkKey = "user_table";
        if (key === "cash_flow_articles") checkKey = "cash_flow_articles_table";
        if (CONTRAGENT_FIELDS.includes(key)) checkKey = "contragent_id";
        if ((formFields as any)?.[checkKey]?.show) {
          if (
            checkKey === "cash_flow_articles_table" &&
            Array.isArray(value) &&
            value.length === 1
          ) {
            const val = {
              cash_flow_article_id: value[0].cash_flow_article_id,
              amount: Number(amount),
            };
            currentValues[key] = [val];
          } else currentValues[key] = value;
          return;
        }
        currentValues[key] = null;
      });

      const data = {
        ...currentValues,
        period: currentValues.period ? dayjs(currentValues.period).format("YYYY-MM-DD") : null,
        id,
        amount: Number(amount),
        date: dayjs(date).format("YYYY-MM-DD"),
        date_delivery: dayjs(date_delivery).format("YYYY-MM-DD"),
        number,
        pay_type_id,
        pay_type_title,
        description,
        operation_type_id,
        operation_type_title,
        spot_bank_account_number,
        spot_bank_account_id,
        spot_id,
        spot_title,
        status_id,
        status_title,
        type_id,
        type_title,
        contragent_type: contragentType,
      };

      if (!transaction) {
        fetchCreateTransaction(data).then(transaction => navigate(`../edit#${transaction?.id}`));
        return;
      }

      updateTransactionConfirm(data);
    },
    [formFields, transaction, updateTransactionConfirm, fetchCreateTransaction, navigate]
  );

  const onApprove = useCallback(
    (values: any) => {
      const contragentType =
        formFields?.contragent_type?.default === 1
          ? "user"
          : formFields?.contragent_type?.default === 2
          ? "business_holder"
          : "contragent";

      const {
        id,
        amount,
        date,
        date_delivery,
        number,
        pay_type_id,
        pay_type_title,
        description,
        operation_type_id,
        operation_type_title,
        spot_bank_account_number,
        spot_bank_account_id,
        spot_id,
        spot_title,
        status_id,
        status_title,
        type_id,
        type_title,
        ...otherValues
      } = values;

      const currentValues: { [key: string]: any } = {};
      Object.entries(otherValues).forEach(([key, value]) => {
        let checkKey = key;
        if (key === "items") checkKey = "user_table";
        if (key === "cash_flow_articles") checkKey = "cash_flow_articles_table";
        if (CONTRAGENT_FIELDS.includes(key)) checkKey = "contragent_id";
        if ((formFields as any)?.[checkKey]?.show) {
          if (
            checkKey === "cash_flow_articles_table" &&
            Array.isArray(value) &&
            value.length === 1
          ) {
            const val = {
              cash_flow_article_id: value[0].cash_flow_article_id,
              amount: Number(amount),
            };
            currentValues[key] = [val];
          } else currentValues[key] = value;
          return;
        }
        currentValues[key] = null;
      });

      const data = {
        ...currentValues,
        period: currentValues.period ? dayjs(currentValues.period).format("YYYY-MM-DD") : null,
        id,
        amount: Number(amount),
        date: dayjs(date).format("YYYY-MM-DD"),
        date_delivery: dayjs(date_delivery).format("YYYY-MM-DD"),
        number,
        pay_type_id,
        pay_type_title,
        description,
        operation_type_id,
        operation_type_title,
        spot_bank_account_number,
        spot_bank_account_id,
        spot_id,
        spot_title,
        status_id,
        status_title,
        type_id,
        type_title,
        contragent_type: contragentType,
      };
      approveTransactionConfirm(data, () => navigate(".."));
    },
    [approveTransactionConfirm, formFields, navigate]
  );

  const isCash = useMemo(() => transaction?.type_title === "Касса", [transaction]);

  if (transactionIsLoading) return <BigSkeleton padding="16px" />;

  return (
    <TransactionContext.Provider value={{ isCash }}>
      <div className={cls["wrapper"]}>
        <TransactionHeader
          onSave={handleSubmit(onSubmit, errors => console.log(errors))}
          onApprove={handleSubmit(onApprove)}
        />
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </div>
    </TransactionContext.Provider>
  );
});
