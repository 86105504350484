import { FC } from "react";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";
export const MIN_DATE = "2024-12-01";

interface DealTitleProps {
  allDisabled: boolean;
}

const DealTitleComponent: FC<DealTitleProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ deal: string }>();

  return (
    <Input
      label="№ договора"
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="text"
      name="deal"
      inputProps={{
        placeholder: "Выбери контрагента",
        disabled: true,
      }}
    />
  );
};

export const DealTitleField = observer(DealTitleComponent);
