import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { IBalanceArticle } from "types/manager-reports";
import cls from "../../table-styles.module.scss";
import Row from "./row";
import { monthsMap } from "utils/functions/mapMonths";
import dayjs from "dayjs";

interface BalanceTableProps {
  title: string;
  articles: IBalanceArticle[];
}

const BalanceTableComponent: FC<BalanceTableProps> = ({ title, articles }) => {
  const balanceData = useMemo(() => {
    if (!articles.length) return <Row />;
    return articles.map((article, i) => <Row key={article.article} article={article} />);
  }, [articles]);

  const monthsData = useMemo(() => {
    const months = monthsMap((month, i) => {
      if (!articles || !articles[0]) return null;
      if (articles[0][month])
        return (
          <th
            key={"balance-" + month}
            className={cls["th"]}
            style={{ textTransform: "capitalize" }}
          >
            {dayjs().month(i).format("MMMM")}
          </th>
        );
      return null;
    });
    if (months.every(m => m === null)) {
      const now = dayjs();
      return months.map((m, i) => {
        if ([now.add(-2, "month").month(), now.add(-1, "month").month()].includes(i))
          return (
            <th key={"balance-" + i} className={cls["th"]} style={{ textTransform: "capitalize" }}>
              {dayjs().month(i).format("MMMM")}
            </th>
          );
        return null;
      });
    }
    return months;
  }, [articles]);

  return (
    <div className={cls["table-container"]}>
      <h3 className={cls["table-title"]}>{title}</h3>
      <table className={cls["table"]}>
        <colgroup>
          <col width={760} />
          {monthsData.map(m => m && <col key={m.key} width={276} />)}
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={cls["th"]}>Статья</th>
            {monthsData}
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>{balanceData}</tbody>
      </table>
    </div>
  );
};

export const BalanceTable = observer(BalanceTableComponent);
