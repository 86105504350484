import { addSpaces } from "assets/utils";
import { FC, useMemo } from "react";
import { IArticleCellValue } from "types/manager-reports";
import { decodeHTMLEntities } from "utils/functions/decodeHTMLEntities";

interface CellValueProps {
  articleCellValue: IArticleCellValue;
}

export const CellValue: FC<CellValueProps> = ({ articleCellValue }) => {
  const { symbol, value } = articleCellValue;

  const val = useMemo(() => {
    if (value === null || isNaN(+value)) return value;

    return addSpaces((+value).toFixed(2));
  }, [value]);

  return (
    <>
      {val} {value !== null && decodeHTMLEntities(symbol ?? "")}
    </>
  );
};
