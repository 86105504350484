import { toJS } from "mobx";
import { EditMoneyTransactionStore } from "../store";
import { IMoneyTransaction, MoneyTransactionDynamicFormFields } from "types/money-transactions";

export type FieldDependencyRule = {
  sourceField: string;
  targetField: string;
  condition?: (sourceValue: any, prevSourceValue: any) => boolean;
  getValue?: (
    sourceValue: any,
    prevSourceValue: any,
    formValues: Record<string, any>,
    store: EditMoneyTransactionStore
  ) => any;
};

export const fieldRules: FieldDependencyRule[] = [
  {
    sourceField: "operation_type_id",
    targetField: "contragent_id",
    condition: (sourceValue, prevSourceValue) => sourceValue !== prevSourceValue,
    getValue(sourceValue, prevSourceValue, formValues, store) {
      const transaction = toJS(store.transaction);
      if (transaction?.operation_type_id && sourceValue === transaction?.operation_type_id)
        return transaction?.contragent_id;
      return formValues.contragent_id;
    },
  },
  {
    sourceField: "contragent_id",
    targetField: "contragent_inn",
    condition: (sourceValue, prevSourceValue) => sourceValue !== prevSourceValue,
    getValue(sourceValue, prevSourceValue, formValues, store) {
      const transaction = toJS(store.transaction);
      if (
        transaction?.[this.sourceField as keyof IMoneyTransaction] &&
        sourceValue === transaction[this.sourceField as keyof IMoneyTransaction]
      )
        return transaction?.[this.targetField as keyof IMoneyTransaction];
      if (sourceValue === null || sourceValue === undefined) return "";
      switch (toJS(store.formFields)?.contragent_type?.default) {
        case 1:
          const user = toJS(store.employees).find(e => e.id === sourceValue);
          return user?.inn ?? "";
        case 2:
          const businessHolder = toJS(store.businessHolders).find(e => e.id === sourceValue);
          return businessHolder?.tax_number ?? "";
        default:
          const contragent = toJS(store.contragents).find(c => c.id === sourceValue);
          return contragent?.inn ?? "";
      }
    },
  },
  {
    sourceField: "contragent_id",
    targetField: "deal_title",
    condition: (sourceValue, prevSourceValue) => sourceValue !== prevSourceValue,
    getValue(sourceValue, prevSourceValue, formValues, store) {
      const transaction = toJS(store.transaction);
      if (
        transaction?.[this.sourceField as keyof IMoneyTransaction] &&
        sourceValue === transaction[this.sourceField as keyof IMoneyTransaction]
      )
        return transaction?.[this.targetField as keyof IMoneyTransaction];
      if (sourceValue === null) return "";
      switch (toJS(store.formFields)?.contragent_type?.default) {
        case 1:
        case 2:
          return "";
        default:
          const contragent = toJS(store.contragents).find(c => c.id === sourceValue);
          return contragent?.deal.title ?? "";
      }
    },
  },
  {
    sourceField: "operation_type_id",
    targetField: MoneyTransactionDynamicFormFields.CashFlowArticleId,
    condition: (sourceValue, prevSourceValue) => sourceValue !== prevSourceValue,
    getValue: (sourceField, prevSourceValue, formValues, store) => {
      const transaction = toJS(store.transaction);
      if (sourceField === transaction?.operation_type_id) {
        return transaction?.cash_flow_article_id;
      }
      const operationType = toJS(store.operationTypes).find(op => op.id === sourceField);
      return operationType?.fields.cash_flow_article_id?.default;
    },
  },
  {
    sourceField: "operation_type_id",
    targetField: MoneyTransactionDynamicFormFields.PnLArticleId,
    condition: (sourceValue, prevSourceValue) => sourceValue !== prevSourceValue,
    getValue: (sourceField, prevSourceValue, formValues, store) => {
      const transaction = toJS(store.transaction);
      if (sourceField === transaction?.operation_type_id) {
        return (transaction as any)?.pnl_article_id;
      }
      const operationType = toJS(store.operationTypes).find(op => op.id === sourceField);
      return operationType?.fields.pnl_article_id?.default;
    },
  },
  {
    sourceField: "cash_flow_articles",
    targetField: "cash_flow_articles[0].amount",
    condition: (sourceValue, prevSourceValue) =>
      prevSourceValue?.length !== 1 && sourceValue?.length === 1,
    getValue: (sourceField, prevSourceValue, formValues, store) => {
      return formValues.amount;
    },
  },
  {
    sourceField: "amount",
    targetField: "cash_flow_articles[0].amount",
    condition: (sourceValue, prevSourceValue) => prevSourceValue !== sourceValue,
    getValue: (sourceField, prevSourceValue, formValues, store) => {
      const cashFlowArticles = formValues.cash_flow_articles;

      if (!cashFlowArticles || cashFlowArticles.length <= 1) {
        return sourceField;
      }
      return cashFlowArticles[0].amount;
    },
  },
  {
    sourceField: "cash_flow_articles[0]",
    targetField: "cash_flow_articles[0].amount",
    condition: (sourceValue, prevSourceValue) =>
      [null, undefined].includes(prevSourceValue) && ![null, undefined].includes(sourceValue),
    getValue: (sourceField, prevSourceValue, formValues, store) => {
      if (sourceField.length === 1) return formValues.amount;
      return sourceField?.amount;
    },
  },
];
