import { FC } from "react";
import { EInputStyleVariant, Input } from "ui-new/input";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react-lite";

//Ограничение ввода 12 символов, если больше, то не вводится
const onNumberInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value.length > 50) {
    e.target.value = e.target.value.slice(0, 50);
  }
};

interface NumberProps {
  allDisabled: boolean;
}

const NumberComponent: FC<NumberProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ number: number }>();

  return (
    <Input
      label="№ документа"
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="string"
      name="number"
      placeholder="Введи номер документа"
      inputProps={{
        disabled: allDisabled,
        onInput: onNumberInput,
      }}
    />
  );
};

export const NumberField = observer(NumberComponent);
