import { useEffect, useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { ReportsPage } from "pages/reports";
import { Header, Sidebar } from "widgets";
import "./styles.scss";
import { SchedulePlanningPage } from "pages/spot/schedule-planning-new";
import { observer } from "mobx-react-lite";
import { RegistryDocument } from "pages/registry-document";
import { TimesheetPage } from "../../../pages/timesheet";
import { DashboardPage } from "pages/dashboard";
import { OperationInputEditPage } from "../../../pages/operation-input/operation-input-edit";
import { BankStatementEditPage } from "pages/money-transactions/money-transaction-edit";
import { BankStatementsPage } from "pages/money-transactions";
import { SalaryFundPage } from "pages/salary-fund";
import { RegistryPage } from "pages/registry";
import { OperationInputPage } from "pages/operation-input";
import { FinancePage } from "pages/finance";
import { useTour } from "@reactour/tour";
import { MainTourSteps } from "../../tours/MainTour/MainTour";
import { useStore } from "../../../store";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import { AccessRights } from "../../../pages/access-rights";

import { LoanPage } from "pages/loan";
import { CreateLoan } from "pages/create-loan";
import { EditLoan } from "pages/edit-loan";
import { SpotPage } from "pages/spot/spot-page";
import { ContractsPage } from "pages/contracts";
import { ContragentLoansPage } from "pages/loans-contragents";
import { StockReportPage } from "pages/stock-report";
import { MonthEnd } from "pages/month-end";
import { IncomePlanning } from "../../../pages/income-planning";
import { PnlReportPage } from "pages/pnl-beta";
import { YearPlanning } from "pages/year-planning";
import { OddsPage } from "../../../pages/odds";
import { ManagerReports } from "pages/manager-reports";

export const MainProcess = observer(() => {
  const {
    RootStore: {
      setIsAuthenticated,
      HeaderStore: { permissions },
    },
  } = useStore();

  //TODO: Костыль, переделать, когда будут реализовываться права доступа
  const canScheduleView = useMemo(() => {
    if (!permissions) return false;

    const schedulePermissions = permissions["Scheduling"];
    if (!schedulePermissions) return false;

    const viewPermission = !Object.entries(schedulePermissions).every(
      ([key, value]) => !value.active
    );

    return viewPermission;
  }, [permissions]);

  const navigate = useNavigate();

  const { setSteps } = useTour();
  useEffect(() => {
    window.addEventListener("storage", e => {
      if (e.oldValue === null || e.newValue !== null) return;

      navigate("/login/auth");
      setIsAuthenticated(false);
    });

    return () => {
      window.removeEventListener("storage", () => {
        navigate("/login/auth");
        setIsAuthenticated(false);
      });
    };
  }, []);

  useEffect(() => {
    //TODO: Костыль для быстрого добавления, избавиться от этого
    let steps = MainTourSteps;
    if (!canScheduleView) {
      steps = steps.filter(s => s.selector !== ".nav__schedule");
    }

    setSteps!(steps);
  }, [canScheduleView]);

  return (
    <ErrorBoundary>
      <div className="mainProcess__wrapper">
        <Header />
        <div className="mainProcess__inner">
          <Sidebar />
          <div className="mainProcess__container">
            <Routes>
              <Route path="/spot" element={<SpotPage />} />
              {/* Планирование выручки нужно было убрать с прямой ссылки */}
              <Route path="/reports/*">
                <Route path="manager/*" element={<ManagerReports />} />
                <Route path="*" element={<ReportsPage />} />
              </Route>
              <Route path="/schedule/*" element={<SchedulePlanningPage />} />
              <Route path="/registry" element={<RegistryPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/registry/*" element={<RegistryDocument />} />
              <Route path="/contracts/*" element={<ContractsPage />} />
              <Route path="/fund" element={<SalaryFundPage />} />
              <Route path="/statements/*">
                <Route path="edit" element={<BankStatementEditPage />} />
                <Route path="create" element={<BankStatementEditPage />} />
                <Route path="*" element={<BankStatementsPage />} />
              </Route>
              <Route path="/timesheet" element={<TimesheetPage />} />
              <Route path="/input" element={<OperationInputPage />} />
              <Route path="/input/edit" element={<OperationInputEditPage />} />
              <Route path="/input/new" element={<OperationInputEditPage />} />
              <Route path="/finance" element={<FinancePage />} />
              <Route path="/access" element={<AccessRights />} />
              <Route path="/loan" element={<LoanPage />} />
              <Route path="/loan/create" element={<CreateLoan />} />
              <Route path="/loan/edit/:loanId" element={<EditLoan />} />
              <Route path="/stock/report" element={<StockReportPage />} />
              <Route path="/loans/*">
                <Route path="contragents" element={<ContragentLoansPage />} />
              </Route>
              <Route path="/month-end" element={<MonthEnd />} />
              <Route path="/income-planning" element={<IncomePlanning />} />
              <Route path="/year-planning" element={<YearPlanning />} />
              <Route path="/pnl-report" element={<PnlReportPage />} />
              <Route path="/odds" element={<OddsPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});
