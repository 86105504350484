import { observer } from "mobx-react-lite";
import {
  FormValues,
  ICashFlowArticleItem,
} from "pages/money-transactions/money-transaction-edit/types";
import { FC, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import cls from "./styles.module.scss";
import { addSpaces } from "assets/utils";
import { CashFlowArticleRow } from "./cash-flow-article-row";
import { useStore } from "store";
import { toJS } from "mobx";
import { getFormErrorMessage } from "utils/functions/table-error-message";

interface CashFlowArticlesTableProps {
  allDisabled: boolean;
}

const newArticle: Partial<ICashFlowArticleItem> = {
  cash_flow_article_id: null as any,
  amount: null as any,
};
//Проверка идёт в operationType по cash_flow_articles_table, но само поле называется cash_flow_articles

const CashFlowArticlesTableComponent: FC<CashFlowArticlesTableProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { cashFlowArticles },
    },
  } = useStore();

  const { control, watch, formState } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({ control, name: "cash_flow_articles" });

  const cashFlowArticlesData = useMemo(
    () =>
      toJS(cashFlowArticles).map(cashFlowArticle => ({
        label: cashFlowArticle.title,
        value: cashFlowArticle.id,
      })),
    [cashFlowArticles]
  );

  const amount = watch("amount");
  const cashFlowArticlesTable = watch("cash_flow_articles");
  const sum = cashFlowArticlesTable?.reduce((acc, val) => acc + Number(val.amount ?? 0), 0);

  const errorMessage = useMemo(() => {
    const x = getFormErrorMessage({
      fields,
      errors: formState.errors,
      fieldName: "cash_flow_articles" as any,
    });
    console.log(x);

    return x;
  }, [fields, formState.errors]);

  return (
    <div>
      <table className={cls["table"]}>
        <colgroup>
          <col width={801} />
          <col width={315} />
          <col width={40} />
        </colgroup>
        <thead className={cls["thead"]}>
          <tr className={cls["tr"]}>
            <th className={cls["th"]}>Статья ДДС</th>
            <th className={cls["th"]} colSpan={2}>
              Сумма
            </th>
          </tr>
        </thead>
        <tbody className={cls["tbody"]}>
          {fields.map((fieldProps, i, arr) => {
            return (
              <CashFlowArticleRow
                key={fieldProps.id}
                index={i}
                remove={remove}
                allDisabled={allDisabled}
                control={control}
                lastIndex={arr.length - 1}
                // cashFlowArticle={fieldProps}
                cashFlowArticlesData={cashFlowArticlesData}
              />
            );
          })}
        </tbody>
        <tfoot className={cls["tfoot"]}>
          <tr className={`${cls["tr"]} ${cls["footer"]}`}>
            <td className={cls["td"]}>
              <div style={{ paddingLeft: "16px" }}>
                {fields.length < 9 && !allDisabled && (
                  <button
                    className={cls["add-button"]}
                    onClick={() => append(newArticle as ICashFlowArticleItem)}
                    disabled={allDisabled}
                  >
                    + добавить статью расходов
                  </button>
                )}
              </div>
            </td>
            <td className={cls["td"]} colSpan={2}>
              <div className={`${cls["sum"]} ${allDisabled && cls["disabled"]}`}>
                <p>Итого сумма:</p>
                <span style={+amount !== +sum ? { color: "#EB5757" } : {}}>
                  {sum ? addSpaces(sum.toFixed(2)) : 0.0}
                </span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div style={{ marginTop: "10px" }}>
        <span className={cls["error-message"]}>{errorMessage}</span>
      </div>
    </div>
  );
};

export const CashFlowArticlesTable = observer(CashFlowArticlesTableComponent);
