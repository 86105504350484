import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import cls from "./styles.module.scss";
import { PositionRow } from "./position-row";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useStore } from "store";
import { addSpaces } from "assets/utils";
import { FormValues } from "../../act/act";
import {
  IActServiceNewPosition,
  IAdvanceReportServiceNewPosition,
  IServiceNewPosition,
} from "../../../../../types/registry-document/services-document";
interface EmployeesTableProps {
  allDisabled: boolean;
}

const PositionsTableComponent: FC<EmployeesTableProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      RegistryServicesDocumentStore: {
        fetchGetPnLChildArticles,
        act,
        advanceReport,
        docType,
        utilityArticle,
      },
    },
  } = useStore();

  const { control, setValue, watch } = useFormContext<FormValues>();
  const { fields, remove } = useFieldArray({ control, name: "positions" });

  const pnlArticleId = watch("pnl_article_id");
  const positions = watch("positions");

  const sum = positions?.reduce((acc, val) => acc + Number(val.sum ?? 0), 0);

  useEffect(() => {
    if (!utilityArticle) return;
    if (docType === "act_service" && act && act.pnl_article_id === utilityArticle.id) return;
    if (
      docType === "advance_report_service" &&
      advanceReport &&
      advanceReport.pnl_article_id === utilityArticle.id
    )
      return;
    if (pnlArticleId !== utilityArticle.id) return;

    fetchGetPnLChildArticles(pnlArticleId).then(articles =>
      setValue(
        "positions",
        articles.map(article => {
          const position = {
            pnl_article_id: article.id,
            pnl_article_title: article.title,
            sum: null as unknown as number,
          } as IServiceNewPosition;
          if (docType === "act_service") {
            (position as IActServiceNewPosition).act_service_id = -1;
          } else if (docType === "advance_report_service") {
            (position as IAdvanceReportServiceNewPosition).advance_report_service_id = -1;
          }
          return position as any;
        })
      )
    );
  }, [
    act,
    advanceReport,
    docType,
    fetchGetPnLChildArticles,
    pnlArticleId,
    setValue,
    utilityArticle,
  ]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={856} />
        <col width={248} />
        <col width={40} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}>Статья расходов</th>
          <th className={cls["th"]}>Стоимость, руб</th>
          <th className={cls["th"]}></th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>
        {fields.map((fieldProps, i, arr) => {
          return (
            <PositionRow
              allDisabled={allDisabled}
              key={fieldProps.id}
              position={fieldProps}
              index={i}
              lastIndex={arr.length - 1}
              control={control}
              remove={remove}
            />
          );
        })}
      </tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={`${cls["tr"]} ${cls["sum-footer"]}`}>
          <td className={cls["td"]}>
            <span>Итого сумма</span>
          </td>
          <td className={cls["td"]} colSpan={2}>
            <span>{sum ? addSpaces(sum.toFixed(2)) : 0.0}</span>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export const PositionsTable = observer(PositionsTableComponent);
